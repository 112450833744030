import {useState, useEffect} from 'react';
import Navbar from '../components/Navbar';
import {getWeb3, doughAddress, doughLPAddress, ovenAddress} from '../utils/loadContracts';
import {getCurrPrice, getWeightedAvgAPR, getWeightedAvgMultiple} from '../utils/utils';
import '../styles/Stats.css';

export default function Stats(props) {
    const [mainStats, setMainStats] = useState({});
    const [doughStats, setDoughStats] = useState({});
    const [lpStats, setLpStats] = useState({});
    const [doughComplexStats, setDoughComplexStats] = useState({});
    const [lpComplexStats, setLpComplexStats] = useState({});
    // const [deposits, setDeposits] = useState([]);

    useEffect(() => {
        console.log("STATS PAGE COMPLEX STATS FETCHING")
        getComplexStats();
    }, [props.activeDepositsFormatted, props.activeDepositsFormattedLP]);

    useEffect(() => {
        console.log("STATS PAGE LOADED")
        getMainStats();
        getStats();
    }, []);



    const getMainStats = async () => {
        try {
            console.log("Getting main stats");
            // tvl = oven balance of dough * dough price + oven balance of dough LP * dough LP price
            const {web3, dough, oven, doughLP} = getWeb3();
            let tvl = 0.0;
            const doughBalance = await dough.methods.balanceOf(ovenAddress).call();
            const doughLPBalance = await doughLP.methods.balanceOf(ovenAddress).call();
            const doughLPSupply = await doughLP.methods.totalSupply().call();
            const lpDoughBalance = await doughLP.methods.balanceOf(doughLPAddress).call();
            const doughPrice = await getCurrPrice();

            tvl += (doughBalance / 1e9) * doughPrice;
            tvl += ((doughLPBalance / doughLPSupply) * lpDoughBalance / 1e9) * doughPrice * 2;

            // circulating dough = dough total supply
            const circulatingDough = await dough.methods.totalSupply().call() / 1e9;
            const circulatingLP = await doughLP.methods.totalSupply().call() / 1e9; //TODO: exclude locked LP in unicrypt

            //marketcap
            const marketCap = doughPrice * circulatingDough;

            setMainStats({
                tvl: tvl,
                marketCap: marketCap,
                circulatingDough: circulatingDough,
                circulatingLP: circulatingLP
            });
            // setMainStats({
            //     tvl: 320100.19,
            //     marketCap: marketCap,
            //     circulatingDough: circulatingDough,
            //     circulatingLP: circulatingLP
            // });
        } catch (error) {
            console.error(error);
        }
    };

    const getComplexStats = async () => {
        console.log("STATS PROPS ACTIVE DEPOSITS", props.activeDepositsFormatted);
        console.log("STATS PROPS ACTIVE DEPOSITS LP", props.activeDepositsFormattedLP);
        let activeDeposits = props.activeDepositsFormatted;
        let activeLPDeposits = props.activeDepositsFormattedLP;
        // weigheted average APR and multiple
        const averageAPR = await getWeightedAvgAPR(activeDeposits);
        const averageMultiple = await getWeightedAvgMultiple(activeDeposits);
        setDoughComplexStats({
            averageAPR: averageAPR * 100,
            averageMultiple: averageMultiple
        });

        const lpAverageAPR = await getWeightedAvgAPR(activeLPDeposits);
        const lpAverageMultiple = await getWeightedAvgMultiple(activeLPDeposits);
        setLpComplexStats({
            averageAPR: lpAverageAPR * 100,
            averageMultiple: lpAverageMultiple
        });
    }

    const getStats = async () => {
        try {
            const {web3, dough, oven, doughLP} = getWeb3();

            //dough stats
            // staked = dough balance of oven
            const staked = await dough.methods.balanceOf(ovenAddress).call();
            
            // percentage of supply staked = staked / total supply
            const totalSupply = await dough.methods.totalSupply().call();
            const percentageStaked = (staked / totalSupply) * 100;

            setDoughStats({
                staked: parseInt(staked) / 1e9,
                percentageStaked: percentageStaked
            });

            //lp stats
            // staked = dough LP balance of oven
            const lpStaked = await doughLP.methods.balanceOf(ovenAddress).call();

            // percentage of supply staked = staked / total supply
            const lpTotalSupply = await doughLP.methods.totalSupply().call();
            const lpPercentageStaked = ((lpStaked / lpTotalSupply) * (await dough.methods.balanceOf(doughLPAddress).call())) / totalSupply * 100;

            setLpStats({
                staked: lpStaked / 1e9,
                percentageStaked: lpPercentageStaked
            });

        } catch (error) {
            console.error(error);
        }

    };

    return (
        <>
            <div className='stats-main-container'>
                <Navbar page='stats' />
                <div className='stats-content-container'>
                    <div className='stats-main'>
                        <div className='stats-main-item'>
                            <div className='stats-item-header brioche-fontface'>
                                TOTAL VALUE LOCKED (TVL)
                            </div>
                            <div className='stats-item-value brioche-fontface'>
                                {'$' + parseInt(mainStats.tvl).toLocaleString()}
                            </div>
                            <div className='stats-item-footer brioche-fontface'>
                                USD 
                            </div>
                        </div>
                        <div className='stats-main-item'>
                            <div className='stats-item-header brioche-fontface'>
                                MARKET CAP
                            </div>
                            <div className='stats-item-value brioche-fontface'>
                                {'$' + parseInt(mainStats.marketCap).toLocaleString()}
                            </div>
                            <div className='stats-item-footer brioche-fontface'>
                                USD 
                            </div>
                        </div>
                        <div className='stats-main-item'>
                            <div className='stats-item-header brioche-fontface'>
                                CIRCULATING DOUGH
                            </div>
                            <div className='stats-item-value brioche-fontface'>
                                {parseInt(mainStats.circulatingDough).toLocaleString()}
                            </div>
                            <div className='stats-item-footer brioche-fontface'>
                                $DOUGH 
                            </div>
                        </div>
                        <div className='stats-main-item' style={{marginBottom:'5%'}}>
                            <div className='stats-item-header brioche-fontface'>
                                CIRCULATING LP TOKENS
                            </div>
                            <div className='stats-item-value brioche-fontface'>
                                {parseInt(mainStats.circulatingLP).toLocaleString()}
                            </div>
                            <div className='stats-item-footer brioche-fontface'>
                                DOUGH/WETH LP 
                            </div>
                        </div>
                    </div>
                    <div className='stats-panel'>
                        <div className='stats-sub-panel'>
                            <div className='brioche-fontface stats-sub-panel-title'>
                                DOUGH STATS
                            </div>
                            <div className='stats-panel-items'>
                                <div className='stats-panel-item'>
                                    <div className='stats-item-header brioche-fontface'>
                                        DOUGH STAKED
                                    </div>
                                    <div className='stats-item-value brioche-fontface'>
                                        {doughStats.staked ? parseInt(doughStats.staked).toLocaleString() : '0'}
                                    </div>
                                    <div className='stats-item-footer brioche-fontface'>
                                        $DOUGH 
                                    </div>
                                </div>
                                <div className='stats-panel-item'>
                                    <div className='stats-item-header brioche-fontface'>
                                        % OF SUPPLY STAKED
                                    </div>
                                    <div className='stats-item-value brioche-fontface'>
                                        {doughStats.percentageStaked ? doughStats.percentageStaked.toFixed(2) + '%' : 'N/A'}
                                    </div>
                                    <div className='stats-item-footer brioche-fontface'>
                                        % 
                                    </div>
                                </div>
                                <div className='stats-panel-item'>
                                    <div className='stats-item-header brioche-fontface'>
                                        AVERAGE APR
                                    </div>
                                    <div className='stats-item-value brioche-fontface'>
                                        {doughComplexStats.averageAPR ? doughComplexStats.averageAPR.toFixed(2) + '%' : 'N/A'}
                                    </div>
                                    <div className='stats-item-footer brioche-fontface'>
                                        % 
                                    </div>
                                </div>
                                <div className='stats-panel-item'>
                                    <div className='stats-item-header brioche-fontface'>
                                        AVERAGE TARGET (Xs)
                                    </div>
                                    <div className='stats-item-value brioche-fontface'>
                                        {doughComplexStats.averageMultiple ? doughComplexStats.averageMultiple.toFixed(2) + 'x' : 'N/A'}
                                    </div>
                                    <div className='stats-item-footer brioche-fontface'>
                                        Xs
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='stats-sub-panel'>
                            <div className='brioche-fontface stats-sub-panel-title-2'>
                                DOUGH/WETH LP STATS
                            </div>
                            <div className='stats-panel-items'>
                                <div className='stats-panel-item'>
                                    <div className='stats-item-header brioche-fontface'>
                                        LP TOKENS STAKED
                                    </div>
                                    <div className='stats-item-value brioche-fontface'>
                                        {lpStats.staked ? parseInt(lpStats.staked).toLocaleString() : '0'}
                                    </div>
                                    <div className='stats-item-footer brioche-fontface'>
                                        $DOUGH 
                                    </div>
                                </div>
                                <div className='stats-panel-item'>
                                    <div className='stats-item-header brioche-fontface'>
                                        % OF SUPPLY STAKED
                                    </div>
                                    <div className='stats-item-value brioche-fontface'>
                                        {lpStats.percentageStaked ? lpStats.percentageStaked.toFixed(2) + '%' : '0%'}
                                    </div>
                                    <div className='stats-item-footer brioche-fontface'>
                                        % 
                                    </div>
                                </div>
                                <div className='stats-panel-item'>
                                    <div className='stats-item-header brioche-fontface'>
                                        AVERAGE APR
                                    </div>
                                    <div className='stats-item-value brioche-fontface'>
                                        {lpComplexStats.averageAPR ? (lpComplexStats.averageAPR).toFixed(2) + '%' : '0%'}
                                    </div>
                                    <div className='stats-item-footer brioche-fontface'>
                                        % 
                                    </div>
                                </div>
                                <div className='stats-panel-item'>
                                    <div className='stats-item-header brioche-fontface'>
                                        AVERAGE TARGET (Xs)
                                    </div>
                                    <div className='stats-item-value brioche-fontface'>
                                        {lpComplexStats.averageMultiple ? lpComplexStats.averageMultiple.toFixed(2) + 'x' : 'N/A'}
                                    </div>
                                    <div className='stats-item-footer brioche-fontface'>
                                        Xs 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}