import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import footerLogo from '../BriocheLabs.png';
import twitterLogo from '../Icon-Twitter.png';
import dextoolsLogo from '../Icon-Dextools.png';
import telegramLogo from '../Icon-Telegram.png';
import docsLogo from '../Icon-Docs.png';
import layer1 from '../Gradient.png';
import layer1m from '../GradientMobile.png';
import layer2 from '../LTCWeb-Building2.gif';
import ltcLogo from '../LTCLogo.png';
import img1 from '../Image 1.png';
import img2 from '../Image 2.png';
import img3 from '../Image 3.png';
import img4 from '../Image 4.png';
import img5 from '../Image 5.png';
import pob1 from '../Icon-Align.png';
import pob2 from '../Icon-Diamond.png';
import pob3 from '../Icon-Chess.png'
import dashboard from '../Dashboard.png';
import {Link} from 'react-router-dom';
import NavBar from '../components/HomeNavbar';
import { Text, Button, HStack, VStack, ChakraProvider } from "@chakra-ui/react";
import theme from '../themes/theme';
import useWindowSize from "../hooks/useWindowSize";
import '../styles/Home.css';


export default function HomeRevamped() {
    const [width, height] = useWindowSize();
    const navigate = useNavigate();

    const landing = useRef(null);
    const pob = useRef(null);
    const features = useRef(null);
    const tokenomics = useRef(null);
    
    const isLandscape = window.innerWidth > window.innerHeight;

    //scrolls to element based on id
    const scrollNavi = (id) => {
        if (id === 'landing') {
            landing.current.scrollIntoView({behavior: 'smooth'});
        } else if (id === 'pob') {
            pob.current.scrollIntoView({behavior: 'smooth'});
        } else if (id === 'features') {
            features.current.scrollIntoView({behavior: 'smooth'});
        } else if (id === 'tokenomics') {
            tokenomics.current.scrollIntoView({behavior: 'smooth'});
        }
    }

    return (
        <ChakraProvider theme={theme}>
        <div className="home-container">
            <NavBar scrollNavi={scrollNavi} />
            <div id='landing' ref={landing} className="home-landing-container">
                {!isLandscape && window.innerWidth < 800 ?
                    <img src={layer1m} alt="gradient" className="gradient-image" />
                    :
                    <img src={layer1} alt="gradient" className="gradient-image" />
                }
                <img src={layer2} alt="bakery" className="bakery-image" />
                <Text className='brioche-fontface home-main-title'>
                    NUMBER GO UP TECHNOLOGY
                </Text>
                <Text className='brioche-fontface home-main-desc'>
                    <b>L3T H1M C00K (Ticker: DOUGH)</b> is a symbol of degen culture and an experiment in decentralized finance. 
                    The first yield-generation protocol where staking is based on a target price rather than a period of time.
                </Text>
                <HStack className='landing-btns-container'>
                    <Button
                        variant="solid"
                        backgroundColor='#FEFF04'
                        color='#000000'
                        borderRadius='0px'
                        border='none'
                        _hover={{
                        border:'2px',
                        borderColor:'#FEFF04',
                        backgroundColor:'#000000',
                        color:'#FEFF04'
                        }}
                        onClick={() => {
                            navigate('/oven/dApp');
                        }}
                        w="100%"
                    >
                        <Text className="brioche-fontface"><b>Launch dApp</b></Text>
                    </Button>
                    <Button
                        variant="solid"
                        backgroundColor='#FEFF04'
                        color='#000000'
                        borderRadius='0px'
                        border='none'
                        _hover={{
                        border:'2px',
                        borderColor:'#FEFF04',
                        backgroundColor:'#000000',
                        color:'#FEFF04'
                        }}
                        onClick={() => {
                            window.open('https://www.l3th1mc00k.xyz/cookbook', '_blank');
                        }}
                        w="100%"
                        marginLeft='5%'
                    >
                        <Text className="brioche-fontface"><b>Read Cookbook</b></Text>
                    </Button>
                </HStack>
                <div className="icons-container">
                    <div className='logos-sub-container'>
                        <a href="https://twitter.com/l3th1mc00k" target="_blank" rel="noreferrer">
                        <img className="icon-image" src={twitterLogo} alt="twitter" />
                        </a>
                        {/* <a className='brioche-fontface' href="https://twitter.com/brioche_erc" target="_blank" rel="noreferrer">
                        Twitter
                        </a> */}
                    </div>
                    <div className='logos-sub-container'>
                        <a href="https://t.me/l3th1mc00k" target="_blank" rel="noreferrer">
                        <img className="icon-image" src={telegramLogo} alt="telegram" />
                        </a>
                        {/* <a className='brioche-fontface' href="https://t.me/+IwYVSLvSu6FlNmRl" target="_blank" rel="noreferrer">
                        Telegram
                        </a> */}
                    </div>
                    <div className='logos-sub-container'>
                        <a href="https://www.dextools.io/app/en/ether/pair-explorer/0xb5B3245e2dcFCA6474e3B8588e6baFEE9B683496" target="_blank" rel="noreferrer">
                        <img className="icon-image" src={dextoolsLogo} alt="dextools" />
                        </a>
                        {/* <a className='brioche-fontface' href="https://www.dextools.io/app/en/ether/pair-explorer/0xcf6daab95c476106eca715d48de4b13287ffdeaa" target="_blank" rel="noreferrer">
                        Dextools
                        </a> */}
                    </div>
                </div>
            </div>

            <div id='pob' ref={pob} className='home-section-container'>
                <Text className='brioche-fontface home-section-title'>
                    PROOF OF BAKE
                </Text>
                <Text className='brioche-fontface home-section-desc'>
                    Since the inception of DeFi we've seen many staking protocols, but they've all had one thing in common: they're time based.
                    i.e You stake X tokens for Y length of time to yield Z %APR. With $DOUGH, you stake X tokens for Y target price to yield Z %APR.
                </Text>
                {isLandscape ? 
                <HStack className='pob-features-container'>
                    <div className='pob-feature'>
                        <img src={pob1} className="pob-feature-img"/>
                        <Text className='brioche-fontface pob-feature-title'>
                            Aligns Incentives
                        </Text>
                        <Text className='brioche-fontface pob-feature-desc'>
                            Staking with a commitment to a target price rather than period of time aligns incentives 
                            between stakers and holders with a common goal: the 🌖
                        </Text>
                    </div>
                    <div className='pob-feature'>
                        <img src={pob2} className="pob-feature-img"/>
                        <Text className='brioche-fontface pob-feature-title'>
                            Made for Diamond Hands
                        </Text>
                        <Text className='brioche-fontface pob-feature-desc'>
                            When your target price is reached, the size of your bag increases along with its value, multiplying the gains.
                            The perfect tool for holders with conviction.
                        </Text>
                    </div>
                    <div className='pob-feature'>
                        <img src={pob3} className="pob-feature-img"/>
                        <Text className='brioche-fontface pob-feature-title'>
                            4D Game Theory
                        </Text>
                        <Text className='brioche-fontface pob-feature-desc'>
                            The rules of LeOven and the many ways to allocate portions of your bag for varying targets create endless strategies
                            based on risk appetite and R:R.
                        </Text>
                    </div>
                </HStack>
                :
                <VStack className='pob-features-container-vert'>
                    <div className='pob-feature-vert'>
                        <img src={pob1} className="pob-feature-img"/>
                        <Text className='brioche-fontface pob-feature-title'>
                            Aligns Incentives
                        </Text>
                        <Text className='brioche-fontface pob-feature-desc'>
                            Staking with a commitment to a target price rather than period of time aligns incentives 
                            between stakers and holders with a common goal: the 🌖
                        </Text>
                    </div>
                    <div className='pob-feature-vert'>
                        <img src={pob2} className="pob-feature-img"/>
                        <Text className='brioche-fontface pob-feature-title'>
                            Made for Diamond Hands
                        </Text>
                        <Text className='brioche-fontface pob-feature-desc'>
                            When your target price is reached, not only
                            does your bag value increase, your bag size increases from rewards as well, multiplying the gains.
                            The perfect tool for those with conviction.
                        </Text>
                    </div>
                    <div className='pob-feature-vert'>
                        <img src={pob3} className="pob-feature-img"/>
                        <Text className='brioche-fontface pob-feature-title'>
                            4D Game Theory
                        </Text>
                        <Text className='brioche-fontface pob-feature-desc'>
                            The rules of LeOven and the many ways to allocate portions of your bag for varying targets create endless strategies
                            based on risk appetite and R:R.
                        </Text>
                    </div>
                </VStack>
                }
                <img src={dashboard} alt="dashboard" className="dashboard-img" />
            </div>


            <div id='features' ref={features} className="home-section-container">
                <Text className='brioche-fontface tokenomics-section-title' style={{marginTop: '5%', marginBottom: '2%'}}>
                    HOW TO MAKE 🍞
                </Text>
                {!isLandscape && window.innerWidth < 800 ?
                <div className="point-container">
                    <div className="point-sub-container-img">
                        <img src={img1} className="point-img" />
                    </div>
                    <div className="point-sub-container">
                        <Text className="brioche-fontface point-title">
                            <b>Acquire $DOUGH on Uniswap</b>
                        </Text>
                        <Text className="brioche-fontface point-desc">
                            $DOUGH is tradeable on Uniswap V2. Swap ETH or stables for $DOUGH to get started. 
                            If you wish to use the LP staking feature of Le Oven, you can provide liquidity to the DOUGH/ETH pool on Uniswap and stake your LP tokens.
                        </Text>
                    </div>
                </div>
                :
                <div className="point-container">
                    <div className="point-sub-container">
                        <Text className="brioche-fontface point-title">
                            <b>Acquire $DOUGH on Uniswap</b>
                        </Text>
                        <Text className="brioche-fontface point-desc">
                            $DOUGH is tradeable on Uniswap V2. Swap ETH or stables for $DOUGH and/or provide liquidity to the DOUGH/ETH pool on Uniswap to get started.
                        </Text>
                    </div>
                    <div className="point-sub-container-img">
                        <img src={img1} className="point-img" />
                    </div>
                </div>
                }
                <div className="point-container">
                    <div className="point-sub-container-img">
                        <img src={img2} className="point-img" />
                    </div>
                    <div className="point-sub-container">
                        <Text className="brioche-fontface point-title">
                            <b>Bake $DOUGH in Le Oven</b>
                        </Text>
                        <Text className="brioche-fontface point-desc">
                            Le Oven is the dApp where you turn that $DOUGH into 🍞. Stake your $DOUGH or DOUGH/WETH LP tokens in Le Oven to earn rewards.
                            LP token stakers receive higher rewards to offset impermanent loss.
                        </Text>
                    </div>
                </div>
                {!isLandscape && window.innerWidth < 800 ?
                <div className="point-container">
                    <div className="point-sub-container-img">
                        <img src={img3} className="point-img" />
                    </div>
                    <div className="point-sub-container">
                        <Text className="brioche-fontface point-title">
                            <b>APR is proportional to target price multiple</b>
                        </Text>
                        <Text className="brioche-fontface point-desc">
                            When staking, set a target price multiple (Xs) at which your staked tokens will unlock. The APR you earn is proportional to the target price multiple.
                        </Text>
                    </div>
                </div>
                :
                <div className="point-container">
                    <div className="point-sub-container">
                        <Text className="brioche-fontface point-title">
                            <b>APR is proportional to target price multiple</b>
                        </Text>
                        <Text className="brioche-fontface point-desc">
                            When staking, set a target price multiple (Xs) at which your staked tokens will unlock. The APR is proportional to the target multiple. The higher the target, the higher the rewards.
                        </Text>
                    </div>
                    <div className="point-sub-container-img">
                        <img src={img3} className="point-img" />
                    </div>
                </div>
                }
                <div className="point-container">
                    <div className="point-sub-container-img">
                        <img src={img4} className="point-img" />
                    </div>
                    <div className="point-sub-container">
                        <Text className="brioche-fontface point-title">
                            <b>Before target price is reached:</b>
                        </Text>
                        <Text className="brioche-fontface point-desc">
                            Claim rewards up to your principal staked amount. <br/>
                            There is also the option to "Jeet" (emergency withdraw) with a penalty, but ONLY if you haven't claimed any rewards.
                        </Text>
                    </div>
                </div>
                {!isLandscape && window.innerWidth < 800 ? 
                <div className="point-container">
                    <div className="point-sub-container-img">
                        <img src={img5} className="point-img" />
                    </div>
                    <div className="point-sub-container">
                        <Text className="brioche-fontface point-title">
                            <b>After target price is reached:</b>
                        </Text>
                        <Text className="brioche-fontface point-desc">
                            Claim any remaining rewards and your principal.
                            Not only will your bag value increase, but your bag size will increase as well, multiplying your ROI.
                        </Text>
                    </div>
                </div>
                :
                <div className="point-container">
                    <div className="point-sub-container">
                        <Text className="brioche-fontface point-title">
                            <b>After target price is reached:</b>
                        </Text>
                        <Text className="brioche-fontface point-desc">
                            Claim any remaining rewards and your principal.
                            Not only will your bag value increase, but your bag size will increase as well, multiplying your ROI.
                        </Text>
                    </div>
                    <div className="point-sub-container-img">
                        <img src={img5} className="point-img" />
                    </div>
                </div>
                }
            </div>


            <div id='tokenomics' ref={tokenomics} className="home-section-container">
                <Text className='brioche-fontface home-section-title'>
                    TOKENOMICS
                </Text>
                <Text className='brioche-fontface home-section-desc'>
                    Staking rewards are sourced partially from taxes and a true burn tax is implemented
                    to ensure that $DOUGH becomes deflationary over time.
                </Text>
                <HStack className="tokenomics-items-container">
                    <div className="tokenomics-item">
                        <Text className="brioche-fontface tokenomics-item-title">
                            SUPPLY
                        </Text>
                        <Text className="brioche-fontface tokenomics-item-desc">
                            1 Billion
                        </Text>
                    </div>
                    <div className="tokenomics-item">
                        <Text className="brioche-fontface tokenomics-item-title">
                            REWARD TAX
                        </Text>
                        <Text className="brioche-fontface tokenomics-item-desc">
                            TBD
                        </Text>
                    </div>
                    <div className="tokenomics-item">
                        <Text className="brioche-fontface tokenomics-item-title">
                            BURN TAX
                        </Text>
                        <Text className="brioche-fontface tokenomics-item-desc">
                            TBD
                        </Text>
                    </div>
                </HStack>
                <HStack className="tokenomics-items-container">
                    <div className="contract-address">
                        <Text className="brioche-fontface tokenomics-item-title">
                            CONTRACT ADDRESS
                        </Text>
                        <code className="brioche-fontface tokenomics-item-desc">
                            0xFc116eA24F002F600e363bdce4b91715fe5e0392
                        </code>
                    </div>
                </HStack>
            </div>


            <div className="main-container-2">
                <img className='footer-container' src={ltcLogo}/>
                <div className="logos-container">
                <div className='logos-sub-container'>
                    <a href="https://twitter.com/l3th1mc00k" target="_blank" rel="noreferrer">
                    <img className="icon-image" src={twitterLogo} alt="twitter" />
                    </a>
                    {/* <a className='brioche-fontface' href="https://twitter.com/brioche_erc" target="_blank" rel="noreferrer">
                    Twitter
                    </a> */}
                </div>
                <div className='logos-sub-container'>
                    <a href="https://t.me/l3th1mc00k" target="_blank" rel="noreferrer">
                    <img className="icon-image" src={telegramLogo} alt="telegram" />
                    </a>
                    {/* <a className='brioche-fontface' href="https://t.me/+IwYVSLvSu6FlNmRl" target="_blank" rel="noreferrer">
                    Telegram
                    </a> */}
                </div>
                <div className='logos-sub-container'>
                    <a href="https://www.dextools.io/app/en/ether/pair-explorer/0xb5B3245e2dcFCA6474e3B8588e6baFEE9B683496" target="_blank" rel="noreferrer">
                    <img className="icon-image" src={dextoolsLogo} alt="dextools" />
                    </a>
                    {/* <a className='brioche-fontface' href="https://www.dextools.io/app/en/ether/pair-explorer/0xcf6daab95c476106eca715d48de4b13287ffdeaa" target="_blank" rel="noreferrer">
                    Dextools
                    </a> */}
                </div>
                <div className='logos-sub-container'>
                    <Link to="/cookbook" target="_blank" rel="noopener noreferrer">
                    <div>
                    <img className="icon-image" src={docsLogo} alt="docs" />
                    </div>
                    </Link>
                    {/* <div className='brioche-fontface' style={{'color': 'grey', 'textDecoration': 'underline'}}>
                    Docs
                    </div> */}
                </div>
                </div>
                <img className="footer-logo" src={footerLogo} alt="logo" />
            </div>
        </div>
        </ChakraProvider>
    );
}