import { act } from 'react';
import {getWeb3, doughAddress, doughLPAddress, ovenAddress} from '../utils/loadContracts';

//get current price of ETH
const getETHPrice = async () => {
    const {ethLP} = getWeb3();
    const reserves = await ethLP.methods.getReserves().call();
    const price = reserves[1] / reserves[0];
    const adjusted_price = price / (10 ** (18 - 6));
    const currPriceETH = 1 / adjusted_price;
    console.log('Current Price of ETH', currPriceETH);
    return currPriceETH;
}

//get current price of dough
const getCurrPrice = async () => {
    try {
        const {dough, doughLP, ethLP} = getWeb3();
        const isWETHReserve0 = (await doughLP.methods.token1().call()) == doughAddress;
        const reserves = await doughLP.methods.getReserves().call();
        let price;
        if (isWETHReserve0) {
            price = ((reserves[0] / 1e18) * (await getETHPrice(ethLP))) / (reserves[1] / 1e9);
        } else {
            price = ((reserves[1] / 1e18) * (await getETHPrice(ethLP))) / (reserves[0] / 1e9);
        }
        // price = new Number(price).toFixed(6);
        console.log('Price', price);
        return price;
    } catch (error) {
        console.error(error);
    }
}

//Helper function that takes a 112x112 fixed point number and returns a float
const fixedPointToFloat = async (fixedPoint, currPriceETH) => {
    if (!currPriceETH) {
        currPriceETH = await getETHPrice();
    }
    let binary = parseInt(fixedPoint).toString(2);
    let decimalBits = binary.slice(-112);
    let principalBits = binary.slice(0, -112);
    let decimal = parseInt(decimalBits, 2);
    let principal = parseInt(principalBits, 2);
    let price = principal + parseFloat('.' + decimal.toString().replace('.', '').split('e')[0]);
    price = (price / 1e18) * currPriceETH;
    price = price.toFixed(6);
    return price;
}

//Helper to calculate APR given amount, shares, total shares, reward per block, decay factor
const calculateAPR = (amount, shares, totalShares, rewardPerBlock, decayFactor) => {
    decayFactor = decayFactor / 1e18;
    const blocksPerYear = 7106 * 365;
    // const rewardsAddedAnnual = rewardPerBlock * blocksPerYear;
    const rewardsAddedAnnual = (rewardPerBlock * decayFactor ** blocksPerYear / Math.log(decayFactor)) - (rewardPerBlock / Math.log(decayFactor)); //definite integral of rewardPerBlock * decay^x from 0 to blocksPerYear
    const rewardsEarnedAnnual = rewardsAddedAnnual * (shares / totalShares);
    const apr = (rewardsEarnedAnnual / amount) * 100;
    return apr;
}

const calculateExpectedAPR = async (amount, shares, isLP) => {
    const {oven, dough} = getWeb3();
    let totalShares = isLP ? await oven.methods.totalSharesLP().call() : await oven.methods.totalShares().call();
    let rewardPerBlock = isLP ? await oven.methods.rewardPerBlockLP().call() : await oven.methods.rewardPerBlock().call();
    let decay = isLP ? await oven.methods.decayLP().call() : await oven.methods.decay().call();
    let equivDoughAmount = isLP ? await calculateEquivDoughAmount(amount) : amount;
    let apr = calculateAPR(equivDoughAmount, shares, totalShares, rewardPerBlock, decay);
    return apr;
};

//get pending rewards from contract
const getPendingRewards = async (depositId, isLP) => {
    try {
        const {oven,} = getWeb3();
        const pendingRewards = await oven.methods.getPendingRewards(depositId, isLP, false).call();
        return pendingRewards;
    } catch (error) {
        console.error(error);
    }
}

//get claimed rewards from contract
const getClaimedRewards = async (depositId, isLP) => {
    try {
        const {oven,} = getWeb3();
        const claimedRewards = (await oven.methods.getDepositInfo(depositId, isLP).call())[1]
        return claimedRewards;
    } catch (error) {
        console.error(error);
    }
}

//get expected APR based on amount, shares, total shares, etc
const computeExpectedAPR = async (oldTotalAmount, oldTotalWeightedMultiple, oldWAA, amount, multiple) => {
    try {
        console.log("GETTING EXPECTED APR, PARAMS: ", oldTotalAmount, oldTotalWeightedMultiple, oldWAA, amount, multiple);
        const newWAA = oldWAA * oldTotalAmount / (oldTotalAmount + amount);
        const newWAM = (oldTotalWeightedMultiple + (amount * multiple)) / (oldTotalAmount + amount);
        console.log("NEW WAA: ", newWAA);
        console.log("NEW WAM: ", newWAM);
        console.log("OLD WAM: ", oldTotalWeightedMultiple / oldTotalAmount);
        console.log("MULTIPLE/WAM: ", multiple / newWAM);
        const expectedAPR = (multiple / newWAM) * newWAA;
        console.log("COMPUTED EXPECTED APR: ", expectedAPR);
        return expectedAPR;
    } catch (error) {
        console.error(error);
    }
}

// get current APR based on pending rewards and time elapsed
const getCurrentAPR = async (deposit, isLP) => {
    try {
        const {web3, oven} = getWeb3();
        const depositTimestamp = (await web3.eth.getBlock(deposit.blockNumber)).timestamp;

        let equivDoughAmount = deposit.returnValues.amount;
        //if isLP, compute equivalent dough amount
        if (isLP) {
            console.log("Block Number: ", deposit.blockNumber);
            equivDoughAmount = await calculateEquivDoughAmountAtBlock(deposit.returnValues.amount, deposit.blockNumber);
        }
        const currentTimestamp = Math.floor(new Date().getTime() / 1000);
        const timeElapsed = currentTimestamp - depositTimestamp;
        const pendingRewards = await getPendingRewards(deposit.returnValues.depositId, isLP);
        const claimedRewards = await getClaimedRewards(deposit.returnValues.depositId, isLP);
        //print type of variable claimedRewards
        let currentAPR = ((365 * 24 * 60 * 60) / timeElapsed) * ((pendingRewards / 1e9) + (claimedRewards / 1e9)) / (equivDoughAmount / 1e9);
        if (currentAPR > 100) {
            console.log("APR INFO: ", {
                timeElapsed: timeElapsed / 3600,
                pendingRewards: pendingRewards / 1e9,
                claimedRewards: claimedRewards / 1e9,
                currentAPR: currentAPR,
                equivDoughAmount: equivDoughAmount / 1e9,
                depositId: deposit.returnValues.depositId
            })
        }
        // console.log("Time Elapsed (hours): ", timeElapsed / 3600);
        // console.log("Pending Rewards: ", pendingRewards / 1e9);
        // console.log("Current APR and Deposit and Pending and Claimed: ", currentAPR, deposit, pendingRewards, claimedRewards);

        if (deposit.returnValues.depositId == 1) {
            return {currentAPR: 1, pendingRewards: 0, claimedRewards: 0}
        }
        return {currentAPR, pendingRewards, claimedRewards};
    } catch (error) {
        console.error(error);
    }
}

const calculateEquivDoughAmount = async (amount) => {
    try {
        const {doughLP,} = getWeb3();
        const reserves = await doughLP.methods.getReserves().call();
        const equivDoughAmount = (amount / await doughLP.methods.totalSupply().call()) * reserves[1] * 2;
        return equivDoughAmount;
    } catch (error) {
        console.error(error);
    }
}

const calculateEquivDoughAmountAtBlock = async (amount, blockNumber) => {
    try {
        const {doughLP,} = getWeb3();
        const reserves = await doughLP.methods.getReserves().call(undefined, blockNumber);
        const equivDoughAmount = (amount / await doughLP.methods.totalSupply().call(undefined, blockNumber)) * reserves[1] * 2;
        return equivDoughAmount;
    } catch (error) {
        console.error(error);
    }
}

//get all active deposits (not withdrawn)
const getActiveDeposits = async (_isLP) => {
    try {
        const {oven, isWalletProvider} = getWeb3();

        //get all deposits and withdrawals, then filter out the withdrawals to get active deposits
        let depositEvents = await oven.getPastEvents(
            _isLP ? 'DepositLP' : 'Deposit',
            {
            fromBlock: 'earliest',
            toBlock: 'latest'
            }
        );
        let withdrawalEvents = await oven.getPastEvents(
            'Withdrawal',
            {
            filter: {isLP: _isLP},
            fromBlock: 'earliest',
            toBlock: 'latest'
            }
        );
        let jeetEvents = await oven.getPastEvents(
            'Jeet',
            {
            filter: {isLP: _isLP},
            fromBlock: 'earliest',
            toBlock: 'latest'
            }
        );
        withdrawalEvents = withdrawalEvents.concat(jeetEvents);
        const activeDeposits = depositEvents.filter((d) => {
            return !withdrawalEvents.some(w => w.returnValues.depositId === d.returnValues.depositId);
        });
        
        return {activeDeposits, isWalletProvider};
    } catch (error) {
        console.error(error);
    }
}

const getActiveDepositsFormattedHelper = async (_isLP) => {
    const storageId = _isLP ? 'depsLP' : 'deps';
    const {activeDeposits, isWalletProvider} = await getActiveDeposits(_isLP);
    //format active deposits and get pending rewards, current APR, and claimed rewards for each
    let activeDepositsFormatted = [];
    const currPriceETH = await getETHPrice();
    for (const d of activeDeposits) {
        let {currentAPR, pendingRewards, claimedRewards} = await getCurrentAPR(d, _isLP);
        activeDepositsFormatted.push({
            account: d.returnValues.user,
            depositId: d.returnValues.depositId,
            amountTokens: d.returnValues.amount / 1e9,
            shares: d.returnValues.shares / 1e9,
            targetPrice: await fixedPointToFloat(d.returnValues.targetPrice, currPriceETH),
            targetMultiple: d.returnValues.shares / d.returnValues.amount,
            pendingRewards: pendingRewards / 1e9,
            claimedRewards: claimedRewards / 1e9,
            currentAPR: currentAPR,
        });
        //wait 20 milliseconds to avoid rate limiting
        if (!isWalletProvider) await new Promise(r => setTimeout(r, 20));
    }
    const storageJSON = JSON.stringify({
        activeDeposits: activeDepositsFormatted,
        lastUpdated: new Date().getTime()
    });
    console.log('Storing active deposits in local storage');
    localStorage.setItem(storageId, storageJSON);
    console.log('Active Deposits', activeDepositsFormatted);
    return activeDepositsFormatted;

}

//get all active deposits formatted for display in the UI
const getActiveDepositsFormatted = async (_isLP, useCache=true) => {
    console.log("Getting active deposits formatted");
    //check localstorage
    if (useCache) {
        const storageId = _isLP ? 'depsLP' : 'deps';
        const storage = localStorage.getItem(storageId);
        if (storage) {
            const storageJSON = JSON.parse(storage);
            const lastUpdated = storageJSON.lastUpdated;
            const now = new Date().getTime();
            //if less than 30 minutes since last update, return cached data
            if (now - lastUpdated < 1800000) {
                console.log('Returning cached active deposits');
                return storageJSON.activeDeposits;
            }
            getActiveDepositsFormattedHelper(_isLP);
            return storageJSON.activeDeposits;
        }
    }
    const res = await getActiveDepositsFormattedHelper(_isLP);
    return res;
}

//get all active deposits formatted, filtered by account
const getActiveDepositsByUser = async (activeDeposits, account) => {
    const activeDepositsFiltered = activeDeposits.filter((d) => {
        return d.account.toLowerCase() == account.toLowerCase();
    });
    console.log('Active Deposits for account: ', activeDepositsFiltered);
    return activeDepositsFiltered;
}

//get weighted avg apr
const getWeightedAvgAPR = async (activeDeposits) => {
    // weigheted average APR
    let totalAPR = 0;
    let totalTokens = 0;
    // const activeDeps = [...activeDeposits].slice(1);
    activeDeposits.forEach(d => {
        totalAPR += d.currentAPR * d.amountTokens;
        totalTokens += d.amountTokens;
    });
    const averageAPR = totalAPR / totalTokens;
    return averageAPR;
}

//get weighted avg target multiple
const getWeightedAvgMultiple = async (activeDeposits, verbose=false) => {
    // weigheted average APR
    let totalMultiple = 0;
    let totalTokens = 0;
    // const activeDeps = [...activeDeposits].slice(1);
    activeDeposits.forEach(d => {
        totalMultiple += d.shares;
        totalTokens += d.amountTokens;
    });
    const averageMultiple = totalMultiple / totalTokens;
    if (verbose) {
        return {
            totalMultiple,
            totalTokens,
            averageMultiple
        }
    }
    return averageMultiple;
}

export {
    getETHPrice,
    getCurrPrice,
    fixedPointToFloat,
    calculateExpectedAPR,
    getPendingRewards,
    getCurrentAPR,
    computeExpectedAPR,
    getActiveDeposits,
    getActiveDepositsFormatted,
    getActiveDepositsByUser,
    getWeightedAvgAPR,
    getWeightedAvgMultiple,
    calculateEquivDoughAmount
}