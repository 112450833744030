import {useState, useEffect} from 'react';
import Navbar from '../components/Navbar';
import BreadBoardLogo from '../BREADBOARDLogo.png';
import '../styles/Board.css';


export default function Board(props) {
    const [isLP, setIsLP] = useState(false);
    const [sortingVariable, setSortingVariable] = useState('shares');
    const [leaderboard, setLeaderbard] = useState([]);

    useEffect(() => {
        getLeaderboard();
    }, [props.activeDepositsFormatted, props.activeDepositsFormattedLP, isLP, sortingVariable]);

    // useEffect(() => {
    //     getLeaderboard();
    // }, [isLP, sortingVariable]);

    // useEffect(() => {
    //     getLeaderboard();
    // }, [sortingVariable]);

    const setLPMode = (_isLP) => {
        setIsLP(_isLP);
    }

    const getLeaderboard = async () => {
        try {
            console.log("LEADERBOARD PROPS LP", props.activeDepositsFormattedLP);
            console.log("LEADERBOARD PROPS", props.activeDepositsFormatted);
            let deposits = isLP ? props.activeDepositsFormattedLP : props.activeDepositsFormatted;
            //sort deposit by sortingVariable
            deposits.sort((a, b) => {
                return b[sortingVariable] - a[sortingVariable];
            });
            //trim to get top 50
            deposits = deposits.slice(0, 50);
            console.log('Leaderboard', deposits);
            setLeaderbard(deposits);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className='main-container'>
            <Navbar page="board" />
            <div className='content-container'>
                <img className='board-logo' src={BreadBoardLogo} alt='BreadBoard Logo' />
                <div className='lp-toggle-container'>
                    {isLP ?
                        <>
                        <button className='brioche-fontface dapp-toggle-btn2' onClick={(e) => setLPMode(false)}>DOUGH</button>
                        <button className='brioche-fontface dapp-toggle-btn' disabled>DOUGH/WETH LP</button>
                        </>
                        :
                        <>
                        <button className='brioche-fontface dapp-toggle-btn' disabled>DOUGH</button>
                        <button className='brioche-fontface dapp-toggle-btn2' onClick={(e) => setLPMode(true)}>DOUGH/WETH LP</button>
                        </>
                    }
                </div>
                <div className='board-container'>
                    <div className='board-title-row'>
                        <div style={{color:'#feff04'}} className='brioche-fontface board-cell-rank'>Rank</div>
                        <div style={{color:'#feff04'}} className='brioche-fontface board-cell-wallet'>Wallet</div>
                        <div style={{color:'#feff04'}} className='brioche-fontface board-cell-amount'>
                            Amount
                            <button className='brioche-fontface board-sort-btn' onClick={(e) => setSortingVariable('amountTokens')}>Sort</button>
                        </div>
                        <div style={{color:'#feff04'}} className='brioche-fontface board-cell-target'>
                            Target (Xs)
                            <button className='brioche-fontface board-sort-btn' onClick={(e) => setSortingVariable('targetMultiple')}>Sort</button>
                        </div>
                        <div style={{color:'#feff04'}} className='brioche-fontface board-cell-shares'>
                            Reward Shares
                            <button className='brioche-fontface board-sort-btn' onClick={(e) => setSortingVariable('shares')}>Sort</button>
                        </div>
                    </div>
                    {leaderboard.map((d, i) => {
                        return (
                            <div key={i} className='board-row'>
                                <div className='brioche-fontface board-cell-rank'>{i + 1}</div>
                                <div className='brioche-fontface board-cell-wallet'>{d.account.slice(0, 6) + '...' + d.account.slice(d.account.length - 4, d.account.length)}</div>
                                <div className='brioche-fontface board-cell-amount'>{parseInt(d.amountTokens).toLocaleString()}</div>
                                <div className='brioche-fontface board-cell-target'>{new Number(d.targetMultiple).toFixed(2) + ' ' + '💎'.repeat(Math.max(1, parseInt(d.targetMultiple/30)))}</div>
                                <div className='brioche-fontface board-cell-shares'>{parseInt(d.shares).toLocaleString()}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}