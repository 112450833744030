import { useState, useEffect } from "react";
import plusIcon from '../Logo-Plus.png';
import minusIcon from '../Logo-Minus.png';
import '../styles/StakeForm.css';


export default function StakeForm(props) {
    const [tokenAmountRaw, setTokenAmountRaw] = useState(0.0);
    const [targetPriceDiff, setTargetPriceDiff] = useState(2.0);
    const [targetPrice, setTargetPrice] = useState(0.0);
    const [expectedAPR, setExpectedAPR] = useState(0.0);

    // console.log('props in StakeForm: ', props);
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            console.log("Target Price Diff: ", targetPriceDiff);
            let priceDiffParsed = parseFloat(targetPriceDiff);
            let finalPriceDiff = priceDiffParsed;
            if (!isNaN(priceDiffParsed) && priceDiffParsed >= 2.0) {
                let priceDiffRounded = (Math.round(priceDiffParsed * 4) / 4); //round to nearest .25
                finalPriceDiff = priceDiffRounded;
            } else {
                finalPriceDiff = 2.0;
            }
            if (props.currPrice > 0.0 && finalPriceDiff > 0.0) {
                let target = new Number(props.currPrice * finalPriceDiff).toFixed(6);
                setTargetPriceDiff(finalPriceDiff);
                setTargetPrice(target);
                console.log("Target Price Diff After: ", targetPriceDiff);
            }
          }, 2000);
        return () => clearTimeout(delayDebounceFn)
    }, [props.currPrice, targetPriceDiff]);

    //get expected APR when amount and multiple is updated
    useEffect(() => {
        async function getAPR() {
            if (targetPriceDiff > 0.0 && parseFloat(tokenAmountRaw) > 0.0) {
                const expectedAPR = await props.getExpectedAPR(parseFloat(tokenAmountRaw), targetPriceDiff);
                await setExpectedAPR(expectedAPR);
            } else {
                setExpectedAPR(0.0);
            }
        }
        getAPR();
    }, [targetPriceDiff, tokenAmountRaw]);

    // useEffect(() => {
    //     async function getAPR() {
    //         let tokenAmountActual = parseInt(parseFloat(tokenAmountRaw) * 1e9);
    //         console.log('tokenAmountActual: ', tokenAmountActual);
    //         let shares = tokenAmountActual * parseFloat(targetPriceDiff);
    //         console.log('shares: ', shares);
    //         let apr = await props.calculateExpectedAPR(tokenAmountActual, shares);
    //         setExpectedAPR(apr);
    //     }
    //     if (targetPrice > 0.0 && parseFloat(tokenAmountRaw) > 0.0) {
    //         getAPR();
    //     }
    // }, [targetPrice, tokenAmountRaw]);

    //approve tokens to be spent by the contract
    const handleApprove = async () => {
        try {
            props.approveTokens(parseInt(parseFloat(tokenAmountRaw) * 1e9).toString());
        } catch (error) {
            console.error(error);
        }
    }

    //deposit tokens to the contract
    const handleDeposit = async () => {
        try {
            await props.depositTokens(parseInt(parseFloat(tokenAmountRaw) * 1e9).toString(), targetPriceDiff);
            // props.setIsStakedImgModalOpen(true);
        } catch (error) {
            console.error(error);
        }
    }

    //handle plus/minus button click on target price difference input
    const handlePlusMinusClick = (plusMinus) => {
        try {
            if (plusMinus === '+') {
                setTargetPriceDiff(targetPriceDiff + 0.25);
            } else if (plusMinus === '-') {
                setTargetPriceDiff(targetPriceDiff - 0.25);
            }
        } catch (err) {
            console.log(err);
        }
    }

    //handle manual price difference input change
    const handleTargetChange = async (e) => {
        try {
            e.preventDefault();
            setTargetPriceDiff(e.target.value);
        } catch (err) {
            console.log(err);
        }
    }

    //handle token amount input change
    const handleTokenAmountChange = async (e) => {
        try {
            e.preventDefault();
            setTokenAmountRaw(e.target.value);
        } catch (err) {
            console.log(err);
        }
    }

    //set max amount or target
    const setMax = (type) => {
        if (type === 'amount') {
            setTokenAmountRaw((props.tokenBalance / 10**9).toString());
        } else if (type === 'target') {
            setTargetPriceDiff(100.0);
        }
    }

    //handle form submission
    const handleSubmit = async (e) => {
        //TODO: approve, deposit, etc

        props.setIsStakedImgModalOpen(true);
    }


    //deposit/approve button based on token balance, allowance, and deposit amount
    let depositButton;
    if (parseFloat(tokenAmountRaw) * 1e9 > parseFloat(props.tokenBalance)) {
        depositButton = <button type="submit" className="brioche-fontface btn-block stake-submit-btn" style={{opacity:'0.7', cursor:'auto'}} disabled>INSUFFICIENT</button>
    } else if (parseFloat(tokenAmountRaw) * 1e9 > parseFloat(props.tokenAllowance)) {
        depositButton = <button type="button" className="brioche-fontface btn-block stake-submit-btn" onClick={handleApprove}>APPROVE</button>
    } else if (parseFloat(tokenAmountRaw) == 0.0) {
        depositButton = <button type="submit" className="brioche-fontface btn-block stake-submit-btn" style={{opacity:'0.7', cursor:'auto'}} disabled>{props.isLP ? 'BAKE DOUGH/WETH LP' : 'BAKE DOUGH'}</button>
    } else if (parseFloat(tokenAmountRaw) == NaN || parseFloat(tokenAmountRaw) < 0.0 || parseFloat(tokenAmountRaw) == Infinity || parseFloat(tokenAmountRaw) == -Infinity || parseFloat(tokenAmountRaw) == null || parseFloat(tokenAmountRaw) == undefined) {
        depositButton = <button type="submit" className="brioche-fontface btn-block stake-submit-btn" style={{opacity:'0.7', cursor:'auto'}} disabled>INVALID AMOUNT</button>
    } else {
        depositButton = <button type="submit" className="brioche-fontface btn-block stake-submit-btn" onClick={handleDeposit}>{props.isLP ? 'BAKE DOUGH/WETH LP' : 'BAKE DOUGH'}</button>
    }


    return (
        <>
            <div className='stake-form-container'>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <div className='brioche-fontface stake-form-stats'>
                        <b>
                        {props.isLP? 'Staked LP Tokens' : 'Staked $DOUGH'}
                        </b>
                    </div>
                    <div className='brioche-fontface' style={{width:'100%', height:'auto', marginTop:'1vh', color:'#FEFF04', fontSize:'2.5vh', lineHeight:'1.25rem'}}>
                        {parseInt(props.stakedDough).toLocaleString()}
                    </div>
                </div>
                <div style={{display:'flex', flexDirection:'column', marginLeft: '20%'}}>
                    <div className='brioche-fontface stake-form-stats'>
                        <b>Rewards Accrued</b>
                    </div>
                    <div className='brioche-fontface' style={{width:'100%', height:'auto', marginTop:'1vh', color:'#FEFF04', fontSize:'2.5vh', lineHeight:'1.25rem'}}>
                        {parseInt(props.totalPendingRewards).toLocaleString()}
                    </div>
                </div>
            </div>
            <div style={{marginLeft: '6.5vw', marginTop:'3vh', width:'100%' }}>
                <form className="brioche-fontface mb-3" onSubmit={(event) => {
                    event.preventDefault();
                    // handleSubmit(event);
                }}>
                    <div className="stake-amount-label">
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center', width:'65%'}}>
                            <label style={{color:'#E1D8CE', fontSize:'1.4vh'}} className="brioche-fontface"><b>{props.isLP ? "LP Amount" : "Dough Amount"}</b></label>
                            <button style={{color:'#000000', backgroundColor:'#FEFF04', fontSize:'1.4vh', lineHeight:'1rem', borderRadius:'0px', paddingLeft:'5%', paddingRight:'5%', paddingTop:'1%', paddingBottom:'1%', marginLeft:'5%'}} onClick={(e) => setMax('amount')} className="brioche-fontface"><b>Max</b></button>
                        </div>
                        <label style={{color:'#E1D8CE', fontSize:'1.4vh', width: '50%', textAlign:'right'}} className="brioche-fontface"><b>{'Balance: ' + parseInt(props.tokenBalance / 10**9).toLocaleString()}</b></label>
                    </div>
                    <div className="brioche-fontface input-group mb-4">
                        <input 
                            // ref={(input) =>  {this.input = input}}
                            id='tokenAmount'
                            type="text"
                            className="brioche-fontface form-control form-control-lg dough-amount-input"
                            placeholder='0'
                            value={tokenAmountRaw}
                            onChange={e => handleTokenAmountChange(e)}
                            required />
                    </div>
                    <div className="stake-amount-label" style={{marginTop:'2vh'}}>
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center', width:'65%'}}>
                            <label style={{color:'#E1D8CE', fontSize:'1.4vh'}} className="brioche-fontface float-left"><b>Target Multiple (Xs)</b></label>
                            <button style={{color:'#000000', backgroundColor:'#FEFF04', fontSize:'1.4vh', lineHeight:'1rem', borderRadius:'0px', paddingLeft:'5%', paddingRight:'5%', paddingTop:'1%', paddingBottom:'1%', marginLeft:'5%'}} onClick={(e) => setMax('target')} className="brioche-fontface"><b>Max</b></button>
                        </div>
                        {targetPriceDiff > 2.0 ?
                        <img src={minusIcon} onClick={() => handlePlusMinusClick('-')} style={{marginLeft:'1vh',  cursor:'pointer', border:'none', height:'2.5vh', width:'2.5vh', marginRight:'1vh', marginLeft:'auto'}}/>
                        :
                        <img src={minusIcon} style={{marginLeft:'1vh',  opacity:'.7', border:'none', height:'2.5vh', width:'2.5vh', marginRight:'1vh', marginLeft:'auto'}}/>
                        }
                        { targetPriceDiff < 100 ?
                        <img src={plusIcon} onClick={() => handlePlusMinusClick('+')} className="plus-icon-grey"/>
                        :
                        <img src={plusIcon} className="plus-icon"/>
                        }
                    </div>
                    <div className="brioche-fontface input-group mb-4">
                        <input 
                            // ref={(input) =>  {this.input = input}}
                            id='targetPriceDiff'
                            type="text"
                            className="brioche-fontface form-control form-control-lg dough-amount-input"
                            value={typeof targetPriceDiff === 'string' ? targetPriceDiff : new Number(targetPriceDiff).toFixed(2).toString()}
                            onChange={e => handleTargetChange(e)}
                            // onKeyDown={targetOnKeyDown}
                            required 
                        />
                    </div>
                    <div className='stake-calculations-row'>
                        <div style={{display:'flex', flexDirection:'column', width: '33%', pointerEvents:'auto'}}>
                            <label style={{color:'#FEFF04', fontSize:'1.4vh'}} className="brioche-fontface float-left"><b>Expected %APR</b></label>
                            <div className="brioche-fontface input-group mb-4">
                                <input 
                                    // ref={(input) =>  {this.input = input}}
                                    id='exoectedAPR'
                                    type="text"
                                    style={{color:'#FEFF04', backgroundColor:'#000000', border: 'none', width:'70%', height:'5vh', fontSize:'2.5vh'}}
                                    className="brioche-fontface"
                                    value={new Number(expectedAPR).toFixed(2).toString()}
                                    disabled />
                            </div>
                        </div>
                        <div style={{display:'flex', flexDirection:'column', width: '33%'}}>
                            <label style={{color:'#FEFF04', fontSize:'1.4vh'}} className="brioche-fontface float-left"><b>Current Price</b></label>
                            <div className="brioche-fontface input-group mb-4">
                                <input 
                                    // ref={(input) =>  {this.input = input}}
                                    id='currentPrice'
                                    type="text"
                                    style={{color:'#FEFF04', backgroundColor:'#000000', border: 'none', width:'70%', height:'5vh', fontSize:'2.5vh'}}
                                    className="brioche-fontface"
                                    value={new Number(props.currPrice).toFixed(6).toString()}
                                    disabled />
                            </div>
                        </div>
                        <div style={{display:'flex', flexDirection:'column', width: '33%'}}>
                            <label style={{color:'#FEFF04', fontSize:'1.4vh'}} className="brioche-fontface float-left"><b>Target Price</b></label>
                            <div className="brioche-fontface input-group mb-4">
                                <input 
                                    // ref={(input) =>  {this.input = input}}
                                    id='targetPrice'
                                    type="text"
                                    style={{color:'#FEFF04', backgroundColor:'#000000', border: 'none', width:'70%', height:'5vh', fontSize:'2.5vh'}}
                                    className="brioche-fontface"
                                    value={new Number(targetPrice).toFixed(6).toString()}
                                    disabled />
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex', flexDirection:'row'}}>
                    {depositButton}
                    <button type="submit" className="brioche-fontface btn-block stake-submit-btn" style={{marginLeft:'5%'}} onClick={() => props.setIsRulesModalOpen(true)}>View Rules</button>
                    </div>
                </form>
            </div>
        </>
    )
}