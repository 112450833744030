import { theme as origTheme, extendTheme } from '@chakra-ui/react'


const theme = extendTheme({
  components: {
    Modal: {
      baseStyle: (props) => ({
        dialog: {
          backgroundColor: "#FEFF04",
          color: "#000000",
          size:'lg',
          borderRadius:'0px'
        }
      })
    },
    Alert: {
      variants: {
        solid: (props) => { // only applies to `solid` variant
          const { colorScheme: c } = props
          if (c !== "blue") {
            // use original definition for all color schemes except "blue"
            return origTheme.components.Alert.variants.solid(props)
          }
          return {
            container: {
              bg: '#FEFF04',
            },
          }
        }
      }
    }
  }
});

export default theme;