import doughLPAbi from '../abi/uniswapV2pair.json';
import doughAbi from '../abi/Dough.json';
import ovenAbi from '../abi/LeOvenV4.json';
import Web3 from 'web3';

// //contract addresses
const doughAddress = '0xFc116eA24F002F600e363bdce4b91715fe5e0392';
const doughLPAddress = '0xb5B3245e2dcFCA6474e3B8588e6baFEE9B683496';
const ovenAddress = '0xf46997fe33D626BFF2784F416D887B3C9a98D309';

//contract addresses
// const doughAddress = '0xF67e26649037695DdFAB19f4E22d5c9Fd1564592';
// const doughLPAddress = '0x0031163c2785347Ca25DCcfDBF6B8E957b48Ad87';
// const ovenAddress = '0x2f321ed425c82E74925488139e1556f9B76a2551';

//get web3 instance and contract instances
export const getWeb3 = () => {
    //web3 instance
    try {
        let web3;
        if (window.web3) {
            web3 = window.web3;
        } else {
            console.log('No web3 instance detected. Provider not found.');
        }
        //dough contract instance
        const dough = new web3.eth.Contract(
            doughAbi.abi,
            doughAddress
        );
        //dough LP contract instance (uniswap V2 pair)
        const doughLP = new web3.eth.Contract(
            doughLPAbi,
            doughLPAddress
        )
        //dough staking contract instance
        const oven = new web3.eth.Contract(
            ovenAbi.abi,
            ovenAddress
        );
        //current eth price
        const ethLP = new web3.eth.Contract(
            doughLPAbi,
            '0xB4e16d0168e52d35CaCD2c6185b44281Ec28C9Dc'
        );
        const isWalletProvider = true;
        return {web3, dough, oven, doughLP, ethLP, isWalletProvider};
    } catch (error) {
        console.log('Error getting web3 from connected wallet');
        try {
            const web3 = new Web3(`https://mainnet.infura.io/v3/ad1652df4ec34a6494374feec76fd4bc`);
             //dough contract instance
            const dough = new web3.eth.Contract(
                doughAbi.abi,
                doughAddress
            );
            //dough LP contract instance (uniswap V2 pair)
            const doughLP = new web3.eth.Contract(
                doughLPAbi,
                doughLPAddress
            )
            //dough staking contract instance
            const oven = new web3.eth.Contract(
                ovenAbi.abi,
                ovenAddress
            );
            //current eth price
            const ethLP = new web3.eth.Contract(
                doughLPAbi,
                '0xB4e16d0168e52d35CaCD2c6185b44281Ec28C9Dc'
            );
            const isWalletProvider = false;
            return {web3, dough, oven, doughLP, ethLP, isWalletProvider};
        } catch (error) {
            console.log('Error getting web3 from infura', error);
            return {web3: null, dough: null, oven: null};
        }
    }
}

export {
    doughAddress,
    doughLPAddress,
    ovenAddress
}