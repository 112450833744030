import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import { getActiveDepositsFormatted } from "./utils/utils";
import Dapp from "./pages/dApp";
import Stats from "./pages/Stats";
import Board from "./pages/Board";

export default function Oven() {
  const [activeDepositsFormatted, setActiveDepositsFormatted] = useState([]);
  const [activeDepositsFormattedLP, setActiveDepositsFormattedLP] = useState([]);

  useEffect(() => {
    (async () => {
      const activeDepsFormatted = await getActiveDepositsFormatted(false);
      const activeDepsFormattedLP = await getActiveDepositsFormatted(true);
      setActiveDepositsFormatted(activeDepsFormatted);
      setActiveDepositsFormattedLP(activeDepsFormattedLP);
    })();
  }, []);

  const updateActiveDeposits = async (_isLP) => {
    const activeDepsFormatted = await getActiveDepositsFormatted(_isLP, false);
    if (_isLP) {
      await setActiveDepositsFormattedLP(activeDepsFormatted);
    } else {
      await setActiveDepositsFormatted(activeDepsFormatted);
    }
  };

  return (
    <>
      <Routes>
        <Route path="dapp" element={<Dapp activeDepositsFormatted={activeDepositsFormatted} activeDepositsFormattedLP={activeDepositsFormattedLP} updateActiveDeposits={updateActiveDeposits} />} />
        <Route path="stats" element={<Stats activeDepositsFormatted={activeDepositsFormatted} activeDepositsFormattedLP={activeDepositsFormattedLP} />} />
        <Route path="breadboard" element={<Board activeDepositsFormatted={activeDepositsFormatted} activeDepositsFormattedLP={activeDepositsFormattedLP} />} />
      </Routes>
    </>
  );
}