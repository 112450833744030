import {
    VStack,
    HStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    Text
  } from "@chakra-ui/react";
  import { Image } from "@chakra-ui/react"
  import { ChakraProvider } from '@chakra-ui/react';
  import theme from '../themes/theme';
  
  
  export default function TransactingModal({ isOpen, closeModal, txnHash }) {

    const openTxn = () => {
      if (txnHash) window.open(`https://etherscan.io/tx/${txnHash}`, "_blank");
    };
  
    return (
      <ChakraProvider theme={theme}>
      <Modal isOpen={isOpen} onClose={closeModal} isCentered>
        <ModalOverlay />
        <ModalContent w="300px">
          <ModalHeader className="brioche-fontface">Transaction in Progress...</ModalHeader>
          <ModalCloseButton
            _focus={{
              boxShadow: "none"
            }}
          />
          <ModalBody paddingBottom="1.5rem">
            <VStack>
              <Button
                variant="solid"
                backgroundColor='#000000'
                color='#FEFF04'
                borderRadius='0px'
                opacity={txnHash ? "1" : "0.7"}
                _hover={{
                  border:'2px',
                  borderColor:'#000000',
                  backgroundColor:'#FEFF04',
                  color:'#000000'
                }}
                onClick={() => {
                  openTxn();
                  closeModal();
                }}
                w="100%"
              >
                <HStack w="100%" justifyContent="center">
                  {/* <Image
                    src="/cbw.png"
                    alt="Coinbase Wallet Logo"
                    width={25}
                    height={25}
                    borderRadius="3px"
                  /> */}
                  <Text className="brioche-fontface">View on Etherscan</Text>
                </HStack>
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      </ChakraProvider>
    );
  }
  