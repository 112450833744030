import {
    VStack,
    HStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    Button,
    Text
  } from "@chakra-ui/react";
import { ChakraProvider } from '@chakra-ui/react';
import themeStaked from '../themes/themeStaked';
import {isBrowser, isMobile } from 'react-device-detect';
  

export default function SurpriseModal({ isOpen, closeModal }) {
  
    return (
      <ChakraProvider theme={themeStaked}>
      <Modal isOpen={isOpen} onClose={closeModal} isCentered>
        <ModalOverlay />
        <ModalContent w={isMobile ? "90%" : "50%"}>
        <VStack>
          <ModalCloseButton
            _focus={{
              boxShadow: "none"
            }}
          />
          <ModalBody paddingBottom="1.5rem">
            <VStack>
              <Text className="brioche-fontface" style={{width:'90%', height:'auto', marginTop:'10%', marginBottom:'0%'}}>
                Congratulations 🎉
              </Text>
              <Text className="brioche-fontface" style={{width:'90%', height:'auto', marginTop:'0%', marginBottom:'5%'}}>
                You've found Chef Doughboy. The first two people to find Chef Doughboy may drop a screenshot (with Chef Doughboy clearly visible) in the Community TG to claim a $DOUGH-ey prize.
              </Text>
              <HStack w="100%" justifyContent="center">
                <Button
                    variant="solid"
                    backgroundColor='#000000'
                    color='#FEFF04'
                    borderRadius='0px'
                    border='1px solid #FEFF04'
                    _hover={{
                    border:'2px',
                    borderColor:'#000000',
                    backgroundColor:'#FEFF04',
                    color:'#000000'
                    }}
                    onClick={() => {
                        closeModal();
                    }}
                    w="40%"
                >
                    <Text className="brioche-fontface" fontSize='xs'>Close</Text>
                </Button>
              </HStack>
              
            </VStack>
          </ModalBody>
          </VStack>
        </ModalContent>
      </Modal>
      </ChakraProvider>
    );
  }
  