import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeRevamped from "./pages/HomeRevamp";
import Where from './pages/Where';
import Cookbook from './pages/Cookbook';
import Docs from './pages/Docs';
import Oven from './Oven';
import './App.css';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeRevamped />} />
        <Route path="/oven/*" element={<Oven />} />
        <Route path="/whereisdoughboy" element={<Where />} />
        <Route path="/cookbook" element={<Cookbook />} />
        //redirects to gitbook
        <Route path='/docs' element={<Docs />} />
      </Routes>
    </BrowserRouter>
  );
}