import {
    VStack,
    HStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    Text
  } from "@chakra-ui/react";
import { useEffect } from "react";
import { Image } from "@chakra-ui/react";
import { ChakraProvider } from '@chakra-ui/react';
import themeStaked from '../themes/themeStaked';
import Tier1 from '../Tier1.png';
import Tier2 from '../Tier2.png';
import Tier3 from '../Tier3.png';
import Tier4 from '../Tier4.png';
import Tier5 from '../Tier5.png';
import { saveAs } from 'file-saver'
import {isBrowser, isMobile } from 'react-device-detect';
  

export default function StakedImgModal({ isOpen, closeModal, multiple }) {
  

    const downloadImg = () => {
        // saveAs('http://localhost:3000/src/StakedGraphic.png', 'Staked.png')
    }

    let stakedImg;
    console.log("MULTIPLE: ", multiple);
    if (parseInt(multiple) < 5) {
        stakedImg = <img src={Tier1} alt="Staked Graphic" style={{width:'90%', height:'auto', marginTop:'10%', marginBottom:'5%'}} />
    } else if (parseInt(multiple) < 10) {
        stakedImg = <img src={Tier2} alt="Staked Graphic" style={{width:'90%', height:'auto', marginTop:'10%', marginBottom:'5%'}} />
    } else if (parseInt(multiple) < 25) {
        stakedImg = <img src={Tier3} alt="Staked Graphic" style={{width:'90%', height:'auto', marginTop:'10%', marginBottom:'5%'}} />
    } else if (parseInt(multiple) < 99) {
        stakedImg = <img src={Tier4} alt="Staked Graphic" style={{width:'90%', height:'auto', marginTop:'10%', marginBottom:'5%'}} />
    } else {
        stakedImg = <img src={Tier5} alt="Staked Graphic" style={{width:'90%', height:'auto', marginTop:'10%', marginBottom:'5%'}} />
    }
  
    return (
      <ChakraProvider theme={themeStaked}>
      <Modal isOpen={isOpen} onClose={closeModal} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent w={isMobile ? "90%" : "100%"}>
        <VStack>
          <ModalCloseButton
            _focus={{
              boxShadow: "none"
            }}
          />
          <ModalBody paddingBottom="1.5rem">
            <VStack>
              <div style={{width:'90%', height:'70%', display:'flex', justifyContent:'center', position:'relative', zIndex:'1'}}>
                {stakedImg}
                <div style={{width:'35%', position:'absolute', bottom:'65%', right:'7%', zIndex:'3', backgroundColor:'transparent', display:'flex', flexDirection:'column'}}>
                  <div className="brioche-fontface" style={{fontSize:'2vh', position:'absolute', width:"100%", color:'#E1D8CE', lineHeight:'1.5rem'}}>I baked my $DOUGH for:</div>
                  <div className="brioche-fontface" style={{fontSize:'5vh', position:'absolute', width:"100%", color:'#E1D8CE', marginTop:'50%'}}>{parseInt(multiple).toString() + 'x'}</div>
                </div>
              </div>
              <HStack w="100%" justifyContent="center">
                <Button
                    variant="solid"
                    backgroundColor='#000000'
                    color='#FEFF04'
                    borderRadius='0px'
                    border='1px solid #FEFF04'
                    _hover={{
                    border:'2px',
                    borderColor:'#000000',
                    backgroundColor:'#FEFF04',
                    color:'#000000'
                    }}
                    onClick={() => {
                        downloadImg();
                    }}
                    w="40%"
                >
                    <Text className="brioche-fontface" fontSize='xs'>Download</Text>
                </Button>
                <Button
                    variant="solid"
                    backgroundColor='#000000'
                    color='#FEFF04'
                    borderRadius='0px'
                    border='1px solid #FEFF04'
                    _hover={{
                    border:'2px',
                    borderColor:'#000000',
                    backgroundColor:'#FEFF04',
                    color:'#000000'
                    }}
                    onClick={() => {
                      downloadImg();
                    }}
                    w="40%"
                >
                    <Text className="brioche-fontface" fontSize='xs'>Share on X</Text>
                </Button>
              </HStack>
              
            </VStack>
          </ModalBody>
          </VStack>
        </ModalContent>
      </Modal>
      </ChakraProvider>
    );
  }
  