import {
    VStack,
    HStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    Text
  } from "@chakra-ui/react";
import { useEffect } from "react";
import { Image } from "@chakra-ui/react";
import { useWeb3React } from "@web3-react/core";
import { connectors } from "../utils/connectors";
import { ChakraProvider } from '@chakra-ui/react';
import theme from '../themes/theme';
  
  export default function WalletSelectModal({ isOpen, closeModal }) {
    const { activate, active, account } = useWeb3React();
  
    const setProvider = (type) => {
      window.localStorage.setItem("provider", type);
    };
  
    return (
      <ChakraProvider theme={theme}>
      <Modal isOpen={isOpen} onClose={closeModal} isCentered>
        <ModalOverlay />
        <ModalContent w="340px">
          <ModalHeader className="brioche-fontface">Select Wallet</ModalHeader>
          <ModalCloseButton
            _focus={{
              boxShadow: "none"
            }}
          />
          <ModalBody paddingBottom="1.5rem">
            <VStack>
              <Button
                variant="solid"
                backgroundColor='#000000'
                color='#FEFF04'
                borderRadius='0px'
                _hover={{
                  border:'2px',
                  borderColor:'#000000',
                  backgroundColor:'#FEFF04',
                  color:'#000000'
                }}
                onClick={() => {
                  activate(connectors.injected);
                  setProvider("injected");
                  closeModal();
                }}
                w="100%"
              >
                <HStack w="100%" justifyContent="center">
                  <Image
                    src="/mm.png"
                    alt="Metamask Logo"
                    width={25}
                    height={25}
                    borderRadius="0px"
                  />
                  <Text className="brioche-fontface">Metamask/Browser Wallet</Text>
                </HStack>
              </Button>
              <Button
                variant="solid"
                backgroundColor='#000000'
                color='#FEFF04'
                borderRadius='0px'
                _hover={{
                  border:'2px',
                  borderColor:'#000000',
                  backgroundColor:'#FEFF04',
                  color:'#000000'
                }}
                onClick={() => {
                  activate(connectors.walletConnect);
                  setProvider("walletConnect");
                  closeModal();
                }}
                w="100%"
              >
                <HStack w="100%" justifyContent="center">
                  <Image
                    src="/wc.png"
                    alt="Wallet Connect Logo"
                    width={26}
                    height={26}
                    borderRadius="0px"
                  />
                  <Text className="brioche-fontface">Wallet Connect</Text>
                </HStack>
              </Button>
              <Button
                variant="solid"
                backgroundColor='#000000'
                color='#FEFF04'
                borderRadius='0px'
                _hover={{
                  border:'2px',
                  borderColor:'#000000',
                  backgroundColor:'#FEFF04',
                  color:'#000000'
                }}
                onClick={() => {
                  activate(connectors.coinbaseWallet);
                  setProvider("coinbaseWallet");
                  closeModal();
                }}
                w="100%"
              >
                <HStack w="100%" justifyContent="center">
                  <Image
                    src="/cbw.png"
                    alt="Coinbase Wallet Logo"
                    width={25}
                    height={25}
                    borderRadius="0px"
                  />
                  <Text className="brioche-fontface">Coinbase Wallet</Text>
                </HStack>
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      </ChakraProvider>
    );
  }
  