import '../styles/DepositCard.css';
import {useState, useEffect} from 'react';
import plusIcon from '../Logo-Plus.png';
import minusIcon from '../Logo-Minus.png';


export default function DepositCard(props) {
    const [isCompounding, setIsCompounding] = useState(false);
    const [targetPriceDiff, setTargetPriceDiff] = useState(2.0);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            console.log("Target Price Diff: ", targetPriceDiff);
            let priceDiffParsed = parseFloat(targetPriceDiff);
            let finalPriceDiff = priceDiffParsed;
            if (!isNaN(priceDiffParsed) && priceDiffParsed >= 2.0) {
                let priceDiffRounded = (Math.round(priceDiffParsed * 4) / 4); //round to nearest .25
                finalPriceDiff = priceDiffRounded;
            } else {
                finalPriceDiff = 2.0;
            }
            if (props.currPrice > 0.0 && finalPriceDiff > 0.0) {
                let target = new Number(props.currPrice * finalPriceDiff).toFixed(6);
                setTargetPriceDiff(finalPriceDiff);
            }
          }, 3000);
        return () => clearTimeout(delayDebounceFn)
    }, [targetPriceDiff]);

    const claim = () => {
        props.claimRewards(props.deposit.depositId, props.deposit.pendingRewards);
    }
    const withdraw = () => {
        props.withdrawTokens(props.deposit.depositId, props.deposit.amountTokens);
    }
    const compoundInitial = () => {
        setIsCompounding(true);
    }
    const compoundFinal = () => {
        props.compoundRewards(props.deposit.depositId, targetPriceDiff, props.deposit.pendingRewards);
        setIsCompounding(false);
    }
    const jeet = () => {
        props.jeet(props.deposit.depositId,  props.deposit.amountTokens);
    }
    //handle plus/minus button click on target price difference input
    const handlePlusMinusClick = (plusMinus) => {
        try {
            if (plusMinus === '+') {
                setTargetPriceDiff(targetPriceDiff + 0.25);
            } else if (plusMinus === '-') {
                setTargetPriceDiff(targetPriceDiff - 0.25);
            }
        } catch (err) {
            console.log(err);
        }
    }
    //handle manual price difference input change
    const handleTargetChange = async (e) => {
        try {
            e.preventDefault();
            setTargetPriceDiff(e.target.value);
        } catch (err) {
            console.log(err);
        }
    }
    const setMax = () => {
        setTargetPriceDiff(100.0);
    }

    return (
        <>
            <div className='deposit-container'>
                <div className='deposit-container-header'>
                    <div className='brioche-fontface deposit-title-1'><b>Deposit ID:</b></div>
                    <div className='brioche-fontface' style={{color:'#FEFF04', fontSize:'2vh', marginLeft:'1%'}}>{props.deposit.depositId}</div>
                </div>
                <div className='deposit-container-row'>
                    <div className='deposit-container-column'>
                        <div className='brioche-fontface deposit-title-1'><b>{props.isLP ? "Amount LP" : "Amount $DOUGH"}</b></div>
                        <div className='brioche-fontface deposit-container-item'>{parseInt(props.deposit.amountTokens).toLocaleString()}</div>
                    </div>
                    <div className='deposit-container-column'>
                        <div className='brioche-fontface deposit-title-1'><b>Target Price</b></div>
                        <div className='brioche-fontface deposit-container-item'>{'$'+props.deposit.targetPrice}</div>
                    </div>
                </div>
                <div className='deposit-container-row'>
                    <div className='deposit-container-column'>
                        <div className='brioche-fontface deposit-title-1'><b>Pending Rewards</b></div>
                        <div className='brioche-fontface deposit-container-item'>{parseInt(props.deposit.pendingRewards).toLocaleString()}</div>
                    </div>
                    <div className='deposit-container-column'>
                        <div className='brioche-fontface deposit-title-1'><b>Current APR</b></div>
                        <div className='brioche-fontface deposit-container-item'>{(props.deposit.currentAPR * 100).toFixed(2).toString() + '%'}</div>
                    </div>
                </div>
                <div className='deposit-btns-first-row'>
                    <div className='deposit-btn-container'>
                        {props.currPrice < props.deposit.targetPrice && props.deposit.pendingRewards ?
                            <button className='brioche-fontface deposit-btn' onClick={claim}><b>Claim</b></button>
                            :
                            <button className='brioche-fontface deposit-btn' style={{opacity:'0.7', pointerEvents:'none'}} disabled><b>Claim</b></button>
                        }
                    </div>
                    <div className='deposit-btn-container'>
                        {props.currPrice < props.deposit.targetPrice && props.deposit.pendingRewards ?
                            <button className='brioche-fontface deposit-btn' onClick={compoundInitial}><b>Compound</b></button>
                            :
                            <button className='brioche-fontface deposit-btn' style={{opacity:'0.7', pointerEvents:'none'}} disabled><b>Compound</b></button>
                        }
                    </div>
                </div>
                <div className='deposit-btns-second-row'>
                    <div className='deposit-btn-container'>
                        {props.currPrice >= props.deposit.targetPrice ?
                            <button className='brioche-fontface deposit-btn' onClick={withdraw}><b>Withdraw</b></button>
                            :
                            <button className='brioche-fontface deposit-btn' style={{opacity:'0.7', pointerEvents:'none'}} disabled><b>Withdraw</b></button>
                        }
                    </div>
                    <div className='deposit-btn-container'>
                        <button className='brioche-fontface deposit-btn' style={{backgroundColor:'#FE2F01'}} onClick={jeet}><b>Jeet</b></button>
                    </div>
                </div>
                {isCompounding ?
                    <>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center', width:'90%', marginBottom:'1%'}}>
                        <label className="brioche-fontface float-left target-label"><b>Target Price Multiple (Xs)</b></label>
                        <button onClick={(e) => setMax('target')} className="brioche-fontface target-max-btn"><b>Max</b></button>
                    </div>
                    <div className="brioche-fontface input-group mb-4 compound-input-div">
                        <input 
                            // ref={(input) =>  {this.input = input}}
                            id='targetPriceDiff'
                            type="text"
                            className="brioche-fontface compound-input"
                            value={typeof targetPriceDiff === 'string' ? targetPriceDiff : new Number(targetPriceDiff).toFixed(2).toString()}
                            onChange={e => handleTargetChange(e)}
                            required 
                        />
                        {targetPriceDiff > 2.0 ?
                        <img src={minusIcon} onClick={() => handlePlusMinusClick('-')} style={{marginLeft:'1vh',  cursor:'pointer', border:'none', height:'2.5vh', width:'2.5vh', marginRight:'.5vh', marginLeft:'auto'}}/>
                        :
                        <img src={minusIcon} style={{marginLeft:'1vh',  opacity:'.7', border:'none', height:'2.5vh', width:'2.5vh', marginRight:'.5vh', marginLeft:'auto'}}/>
                        }
                        { targetPriceDiff < 100 ?
                        <img src={plusIcon} onClick={() => handlePlusMinusClick('+')} className="plus-grey"/>
                        :
                        <img src={plusIcon} className="plus"/>
                        }
                    </div>
                    <div style={{display:'flex', flexDirection:'row', width:'80%', marginTop:'3%', marginBottom:'3%'}}>
                        <button type="submit" className="brioche-fontface btn-block deposit-btn" onClick={compoundFinal}><b>Compound</b></button>
                        <button type="submit" className="brioche-fontface btn-block deposit-btn" onClick={() => setIsCompounding(false)}><b>Cancel</b></button>
                    </div>
                    </>
                    :
                    null
                }
            </div>
        </>
    )
}