import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from 'web3';

const root = ReactDOM.createRoot(document.getElementById('root'));
const getLibrary = (provider) => {
  const library = new Web3(provider);
  window.web3 = library;
  library.pollingInterval = 8000; // frequency provider is polling
  return library;
};
window.Buffer = require('buffer/').Buffer;
root.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
        <App />
    </Web3ReactProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
