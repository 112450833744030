import {
    VStack,
    HStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    Text
  } from "@chakra-ui/react";
import { useEffect } from "react";
import { Image } from "@chakra-ui/react";
import { ChakraProvider } from '@chakra-ui/react';
import themeStaked from '../themes/themeStaked';
import {isBrowser, isMobile } from 'react-device-detect';
import claimedDoughboy from '../Dapp-Claim.png';
import withdrawDoughboy from '../Dapp-Withdraw.png';
import compoundDoughboy from '../Dapp-Compound.png';
import jeetDoughboy from '../Dapp-Jeet.png';
  

export default function ActionModal({ isOpen, closeModal, amount, mode, isLP }) {

    let actionStr;
    let srcImg;
    if (mode === 'claim') {
      actionStr = `You just claimed ${amount && amount > 0 ? parseInt(amount).toLocaleString() + ' ' : ''}$DOUGH rewards 🍞🍞🍞`;
      srcImg = claimedDoughboy;
    } else if (mode === 'withdraw') {
      actionStr = `You just withdrew ${amount && amount > 0 ? parseInt(amount).toLocaleString() + ' ' : ''}${isLP ? "LP Tokens" : "$DOUGH"} from LeOven 🍞🍞🍞`;
      srcImg = withdrawDoughboy;
    } else if (mode === 'compound') {
      actionStr = `You just compounded ${amount && amount > 0 ? parseInt(amount).toLocaleString() + ' ' : ''}$DOUGH rewards 🍞🍞🍞`;
      srcImg = compoundDoughboy;
    } else if (mode === 'jeet') {
      actionStr = `You just jeeted ${amount && amount > 0 ? parseInt(amount).toLocaleString() + ' ' : ''}${isLP ? "LP Tokens" : "$DOUGH"} from LeOven`;
      srcImg = jeetDoughboy;
    }
  
    return (
      <ChakraProvider theme={themeStaked}>
      <Modal isOpen={isOpen} onClose={closeModal} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent w={isMobile ? "90%" : "100%"}>
        <VStack>
          <ModalCloseButton
            _focus={{
              boxShadow: "none"
            }}
          />
          <ModalBody paddingBottom="1.5rem">
            <VStack>
              <img src={srcImg} style={{width: '80%', height: 'auto', marginTop:'10%'}}/>
              <Text className="brioche-fontface" marginBottom='5%' color='#FEFF04' fontSize='lg' textAlign='center' w="80%">{actionStr}</Text>
              <HStack w="100%" justifyContent="center">
                <Button
                    variant="solid"
                    backgroundColor='#000000'
                    color='#FEFF04'
                    borderRadius='0px'
                    border='1px solid #FEFF04'
                    _hover={{
                    border:'2px',
                    borderColor:'#000000',
                    backgroundColor:'#FEFF04',
                    color:'#000000'
                    }}
                    onClick={() => {
                      closeModal();
                    }}
                    w="40%"
                >
                    <Text className="brioche-fontface" fontSize='xs'>Close</Text>
                </Button>
              </HStack>
              
            </VStack>
          </ModalBody>
          </VStack>
        </ModalContent>
      </Modal>
      </ChakraProvider>
    );
  }
  