import { theme as origTheme, extendTheme } from '@chakra-ui/react'


const themeStaked = extendTheme({
  components: {
    Modal: {
      baseStyle: (props) => ({
        dialog: {
          backgroundColor: "#000000",
          color: "#FEFF04",
          sizes: {
            lg: {
              w: "100vw"
            }
          },
          borderRadius:'0px',
          border:'3px solid #FEFF04'
        }
      })
    }
  }
});

export default themeStaked;