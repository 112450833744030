import { useNavigate } from "react-router-dom";
import { useState } from "react";
import logo from '../LeOvenLogo.png';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';
import '../styles/Navbar.css';


export default function Navbar(props) {
    const navigate = useNavigate();
    const [selectedPage, setSelectedPage] = useState('home'); //['dapp', 'stats', 'board']
    const [selectedItem, setSelectedItem] = useState('');

    function handleNavbarItemClick(item) {
      try {
        if (item == 'home') {
          navigate("/");
        } else if (item == 'dapp') {
          navigate("/oven/dApp");
        } else if (item == 'board') {
          navigate("/oven/breadboard");
        } else if (item == 'stats') {
          navigate("/oven/stats");
        }
      } catch (err) {
        console.log(err);
      }
    }

    async function handleWalletConnect() {
      try {
        await props.connectWallet();
      } catch (err) {
        console.log(err);
      }
    }

    
    let wrapperDivColor = '#000000';
    if (props.isDapp) {
      wrapperDivColor = '#FEFF04';
    }

    let walletInfo;
    if (props.account) {
      walletInfo = 
        <div style={{display:'flex', flexDirection:'row', alignItems:'center', marginRight:'1.5vh', cursor:'pointer'}} onClick={handleWalletConnect}>
          <Jazzicon diameter={30} seed={jsNumberForAddress(props.account)} />
          <div className='brioche-fontface' style={{color:'#000000', fontSize:'1.5vh', lineHeight:'1rem', marginLeft:'.75vw'}}>
            {props.account.slice(0, 4) + '...' + props.account.slice(props.account.length - 2, props.account.length)}
          </div>
        </div>
    } else {
      walletInfo =
        <button className='brioche-fontface connect-wallet-btn' onClick={() => handleWalletConnect()}>
          CONNECT WALLET
        </button>
    }


    return (
      <>
        <div style={{backgroundColor:'#FEFF04', width:'100vw', height:'6.5vh', display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', position:'fixed', zIndex:'1'}} >
          <img src={logo} className="le-oven-logo" onClick={() => navigate("/")} />
          {props.page !== 'dapp' ?
            <button className='brioche-fontface connect-wallet-btn' onClick={() => handleNavbarItemClick('dapp')}>
              LAUNCH DAPP
            </button>
            :
            walletInfo
          }
        </div>
        <div style={{backgroundColor:'none', width:'100vw', height:'6.5vh', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', position:'fixed', zIndex:'2', pointerEvents:'none'}}>
          <button className='brioche-fontface navbar-btn' onClick={() => handleNavbarItemClick('home')}>
            Home
          </button>
          {props.page !== 'stats' ? 
            <button className='brioche-fontface navbar-btn' onClick={() => handleNavbarItemClick('stats')}>
              Stats
            </button> 
            :
            <button className='brioche-fontface navbar-btn' style={{borderBottom:'3px solid #000000'}} disabled>
              Stats
            </button> 
          }
          {props.page !== 'board' ? 
            <button className='brioche-fontface navbar-btn' onClick={() => handleNavbarItemClick('board')}>
              BreadBoard
            </button>
            :
            <button className='brioche-fontface navbar-btn' style={{borderBottom:'3px solid #000000'}} disabled>
              BreadBoard
            </button>
          }
        </div>
      </>
    )
}