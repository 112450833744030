import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Link, Box, Flex, Text, Button, Stack, ChakraProvider, Image, HStack } from "@chakra-ui/react";
import themeStaked from '../themes/themeStaked';
import Logo from "../LTCLogo.png";
import Doughboy from "../og_doughboy.jpg";
import '../styles/Home.css';

const NavBar = ({scrollNavi}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <ChakraProvider theme={themeStaked}>
    <NavBarContainer position='fixed' zIndex='2'>
      <HStack className='navbar-icons-container'>
        <Image src={Doughboy} alt="Doughboy" className="doughboy" />
        <Image src={Logo} alt="Logo" className="cook-logo" />
      </HStack>
      <MenuToggle toggle={toggle} isOpen={isOpen} />
      <MenuLinks isOpen={isOpen} scrollNavi={scrollNavi} setIsOpen={setIsOpen} />
    </NavBarContainer>
    </ChakraProvider>
  );
};

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="white"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const MenuToggle = ({ toggle, isOpen }) => {
  return (
    <Box display={{ base: "block", lg: "none" }} backgroundColor='rgba(0,0,0,.5)' onClick={toggle}>
      {isOpen ? <CloseIcon /> : <MenuIcon />}
    </Box>
  );
};

const MenuItem = ({ children, isLast, to = "/", ...rest }) => {
  return (
    <Link href={to}>
      <Text display="block" {...rest}>
        {children}
      </Text>
    </Link>
  );
};

const openCookbook = () => {
    window.open('https://www.l3th1mc00k.xyz/cookbook', '_blank');
}

const MenuLinks = ({ isOpen, scrollNavi, setIsOpen }) => {
  const navigate = useNavigate();
  const scrollNavigate = (e, ref) => {
    e.preventDefault();
    setIsOpen(false);
    scrollNavi(ref);
  }
  return (
    <Box
      display={{ base: isOpen ? "block" : "none", lg: "block" }}
      backgroundColor={{ base: "#000000", lg: "transparent" }}
      flexBasis={{ base: "100%", lg: "auto" }}
    >
      <Stack
        spacing={8}
        align="center"
        justify={["center", "space-between", "flex-end", "flex-end"]}
        direction={["column", "row", "row", "row"]}
        pt={[4, 4, 0, 0]}
      >
        <MenuItem className='brioche-fontface' onClick={(e) => openCookbook()}>Whitepaper</MenuItem>
        <MenuItem className='brioche-fontface' onClick={(e) => scrollNavigate(e, 'pob')}>About</MenuItem>
        <MenuItem className='brioche-fontface' onClick={(e) => scrollNavigate(e, 'features')}>How It works</MenuItem>
        <MenuItem className='brioche-fontface' onClick={(e) => scrollNavigate(e, 'tokenomics')}>Tokenomics</MenuItem>
        <MenuItem className='brioche-fontface' onClick={(e) => {e.preventDefault(); navigate('/docs')}}>Docs</MenuItem>
        {/* <MenuItem className='brioche-fontface' onClick={(e) => scrollNavigate(e, 'series')}>Series</MenuItem> */}
        <MenuItem className='brioche-fontface' isLast>
          <Button
            variant="solid"
            backgroundColor='#FEFF04'
            color='#000000'
            borderRadius='0px'
            border='none'
            _hover={{
            border:'2px',
            borderColor:'#FEFF04',
            backgroundColor:'#000000',
            color:'#FEFF04'
            }}
            onClick={(e) => {
              e.preventDefault();
              navigate('/oven/dApp');
            }}
            w="100%"
          >
            <Text className="brioche-fontface"><b>Launch dApp</b></Text>
          </Button>
        </MenuItem>
      </Stack>
    </Box>
  );
};

const NavBarContainer = ({ children, ...props }) => {
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      mb={8}
      p={5}
      backgroundColor={{ base: "#000000", md: 'rgba(0,0,0,.5)'}}
      color='#FFFFFF'
      {...props}
    >
      {children}
    </Flex>
  );
};

export default NavBar;