import puzzle from '../Puzzle1.jpg';
import doughboi from '../PuzzleMan.png';
import SurpriseModal from '../components/SurpriseModal';
import { useEffect, useState, useMemo } from 'react';


export default function Where() {
    const [last10, setLast10] = useState([]);
    const [surpriseActivated, setSurpriseActivated] = useState(false);

    const coordinateX = useMemo(() => {
        return Math.max(Math.min(Math.random()*100, 90), 10) +'vw';
    }, []);
    const coordinateY = useMemo(() => {
        return Math.max(Math.min(Math.random()*100, 90), 10) +'vh';
    }, []);

    useEffect(() => {}, [surpriseActivated]);

    const handleMouseEnter = (e) => {
        e.target.style.opacity = '0';
        e.target.style.pointerEvents = 'none';
        e.target.style.touchAction = 'none';
        if (last10.length < 10) {
            setLast10([...last10, e]);
        } else {
            let first = last10.shift();
            first.target.style.opacity = '.95';
            first.target.style.pointerEvents = 'auto';
            first.target.style.touchAction = 'auto';
            setLast10([...last10, e]);
        }
    }

    const handleSurprise = (e) => {
        setSurpriseActivated(true);
    }

    //full screen image, with black square divs in a grid on top of it, where each div becomes transparent on hover
    return (
        <>
            <div style={{width: '100vw', height:'100vh', backgroundImage:`url(${puzzle})`, backgroundSize:'cover', backgroundPosition:'center', position:'relative', zIndex:'1', overflow:'hidden'}}>
                <img src={doughboi} onClick={(e) => handleSurprise(e)} style={{position:'absolute', top:coordinateY, left:coordinateX, width:'auto', height:'3.43%', zIndex:'2', cursor:'pointer', pointerEvents:'auto', touchAction:'auto'}}/>
                <div style={{position:'absolute', top:'0', left:'0', width:'100vw', height:'100vh', zIndex:'3', display:'grid', gridTemplateColumns:'repeat(10, 1fr)', gridTemplateRows:'repeat(10, 1fr)', gridColumnGap:'0px', gridRowGap:'0px', pointerEvents:'none'}}>
                    {Array(100).fill().map((_, i) => {
                        return <div 
                                key={i}
                                style={{backgroundColor:'black', opacity:'.95', transition:'opacity 0.5s', cursor:'pointer', pointerEvents:'auto', touchAction:'auto'}} 
                                onMouseEnter={(e) => handleMouseEnter(e)}
                                onMouseDownCapture={(e) => handleMouseEnter(e)}
                                // onMouseDownCapture={(e) => {e.target.style.opacity = '0'; e.target.style.pointerEvents='none'; e.target.style.touchAction='none'}}
                                // onMouseLeave={(e) => {e.target.style.opacity = '.95'; e.target.style.pointerEvents='auto'; e.target.style.touchAction='auto'}}
                                // onMouseUpCapture={(e) => {e.target.style.opacity = '.95'; e.target.style.pointerEvents='auto'; e.target.style.touchAction='auto'}}
                                />
                    })}
                </div>
                <SurpriseModal isOpen={surpriseActivated} closeModal={() => setSurpriseActivated(false)} />
            </div>
        </>
    )
}