import Navbar from '../components/Navbar';
import StakeForm from '../components/StakeForm';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useWeb3React } from "@web3-react/core";
import { useToast } from '@chakra-ui/react';
import WalletSelectModal from '../components/WalletSelectModal';
import TransactingModal from '../components/TransactingModal';
import DepositCard from '../components/DepositCard';
import StakedImgModal from '../components/StakedImgModal';
import ActionModal from '../components/ActionModal';
import RulesModal from '../components/RulesModal';
import {getWeb3, ovenAddress} from '../utils/loadContracts';
import {getCurrPrice, getExpectedAPR, getActiveDepositsByUser, getWeightedAvgAPR, getWeightedAvgMultiple, computeExpectedAPR} from '../utils/utils';
import useWindowSize from "../hooks/useWindowSize";
import '../styles/dApp.css';


//Main dApp page
export default function DApp(props) {
    const navigate = useNavigate();

    const { library, chainId, account, active } = useWeb3React();
    const [isTransacting, setIsTransacting] = useState(false); //whether or not the user is currently transacting
    const [txnHash, setTxnHash] = useState(''); //hash of the current transaction
    const [isWalletConnectModalOpen, setIsWalletConnectModalOpen] = useState(false); //whether or not the wallet connect modal is open
    const [isStakedImgModalOpen, setIsStakedImgModalOpen] = useState(false); //whether or not the staked img modal is open
    const [isActionModalOpen, setIsActionModalOpen] = useState(false); //whether or not the claimed modal is open
    const [actionModalMode, setActionModalMode] = useState(''); //mode of the claimed modal [claim, withdraw, compound, jeet
    const [actionModalAmount, setActionModalAmount] = useState(0); //amount for the claimed modal
    const [isRulesModalOpen, setIsRulesModalOpen] = useState(true); //whether or not the rules modal is open
    const [deposits, setDeposits] = useState([]); //array of the user's deposits
    const [tokenBalance, setTokenBalance] = useState(0); //amount of tokens in the user's wallet
    const [tokenAllowance, setTokenAllowance] = useState(0); //amount of tokens approved to be spent by the contract
    const [totalPendingRewards, setTotalPendingRewards] = useState(0); //amount of circulating dough
    const [stakedDough, setStakedDough] = useState(0); //amount of dough staked in the contract
    const [currPrice, setCurrPrice] = useState(0.0); //current price of dough
    const [totalShares, setTotalShares] = useState(0); //total number of shares in the contract
    const [targetMultiple, setTargetMultiple] = useState(2.0); //target multiple for baking temperature
    const [currAPRForPool, setCurrAPRForPool] = useState(0); //current APR for the pool
    const [isLP, setIsLP] = useState(false); //whether or not the user is providing liquidity
    const [version, setVersion] = useState('full'); //version of the dApp (demo or full)

    const [width, height] = useWindowSize();

    const toast = useToast(); //toast hook

    //load user data on account change
    useEffect(() => {
        console.log(account);
        if (account) {
            getTokenBalance();
            getTokenAllowance();
            getPrice();
            getShareInfo();
            getDeposits();
        } else {
            getPrice();
            getShareInfo();
            getDeposits();
        }
    }, [account]);

    //reload data on isLP change
    useEffect(() => {
        console.log('LP Mode: ', isLP);
        reloadData();
    }, [isLP]);

    //get deposits again when props.activeDepositsFormatted changes
    useEffect(() => {
        getDeposits();
    }, [props.activeDepositsFormatted, props.activeDepositsFormattedLP]);

    //reload data after txn
    const reloadData = (updateActiveDeposits=false) => {
        if (account) {
            getTokenBalance();
            getTokenAllowance();
            getPrice();
            getShareInfo();
            if (updateActiveDeposits) {
                props.updateActiveDeposits(isLP);
            } else {
                getDeposits();
            }
        }
    }

    //close welcome modal and open rules modal
    const closeWelcomeAndOpenRules = () => {
        // setIsWelcomeModalOpen(false);
        setIsRulesModalOpen(true);
    }

    const getPrice = async () => {
        const price = await getCurrPrice();
        setCurrPrice(price);
    }
    

    //approve tokens to be spent by the contract
    const approveTokens = (tokenAmountRaw) => {
        try {
            if (version === 'demo') return;
            const {dough, doughLP} = getWeb3();
            setIsTransacting(true);
            if (isLP) {
                doughLP.methods.approve(ovenAddress, tokenAmountRaw).send({from: account})
                .on('confirmation', () => {
                    console.log('confirmed');
                })
                .on('transactionHash', (hash) => {
                    setTxnHash(hash);
                })
                .on('receipt', () => {
                    // setIsTransacting(false);
                    reloadData();
                })
                .on('error', (error) => {
                    console.error(error);
                    setIsTransacting(false);
                })
            } else {
                dough.methods.approve(ovenAddress, tokenAmountRaw).send({from: account})
                .on('confirmation', () => {
                    console.log('confirmed');
                })
                .on('transactionHash', (hash) => {
                    setTxnHash(hash);
                })
                .on('receipt', () => {
                    // setIsTransacting(false);
                    reloadData();
                })
                .on('error', (error) => {
                    console.error(error);
                    setIsTransacting(false);
                })
            }
        } catch (error) {
            console.error(error);
        }
    }

    //get token allowance
    const getTokenAllowance = async () => {
        try {
            const {dough, doughLP} = getWeb3();
            const allowance = isLP ? await doughLP.methods.allowance(account, ovenAddress).call() : await dough.methods.allowance(account, ovenAddress).call()
            setTokenAllowance(allowance);
            return allowance;
        } catch (error) {
            console.error(error);
        }
    }

    //get token balance
    const getTokenBalance = async () => {
        try {
            const {dough, doughLP} = getWeb3();
            const balance = isLP ? await doughLP.methods.balanceOf(account).call() : await dough.methods.balanceOf(account).call();
            setTokenBalance(balance);
            return balance;
        } catch (error) {
            console.error(error);
        }
    }

    //get circulating $DOUGH
    const getTotalPendingRewards = async (deposits) => {
        try {
            const totalPendingRewards = deposits.length ? deposits.reduce((acc, curr) => acc + parseInt(curr.pendingRewards), 0) : 0;
            setTotalPendingRewards(totalPendingRewards);
            return totalPendingRewards;
        } catch (error) {
            console.error(error);
        }
    }

    //get total $DOUGH staked
    const getStakedDough = async (deposits) => {
        try {
            //sums up the amount of dough staked in each active deposit
            let totalStakedDough = deposits.length ? deposits.reduce((acc, curr) => acc + parseInt(curr.amountTokens), 0) : 0;
            console.log('Total staked dough', totalStakedDough);
            setStakedDough(totalStakedDough);
            return totalStakedDough;
        } catch (error) {
            console.error(error);
        }
    }

    //get total shares in the contract
    const getShareInfo = async () => {
        try {
            const {oven,} = getWeb3();
            const totalShares = isLP ? await oven.methods.totalSharesLP().call() : await oven.methods.totalShares().call();
            const accRewardPerShare = isLP ? await oven.methods.accRewardPerShareLP().call() : await oven.methods.accRewardPerShare().call();
            setTotalShares(totalShares);
            return {totalShares, accRewardPerShare};
        } catch (error) {
            console.error(error);
        }
    }

    //computes baking temp for deposit as the decimal representation of a Q126.2 binary fixed point number
    //used for input in contract deposit function
    const computeBakingTemperature = (multiple) => {
        return parseInt(multiple * 4);
    }

    //deposit tokens to LeOven
    const depositTokens = async (tokenAmountRaw, multiple) => {
        try {
            await setTargetMultiple(multiple);
            await setIsTransacting(true);
            console.log("Deposit Target Multiple: ", multiple);
            const {web3, dough, oven} = getWeb3();
            const bakingTemp = computeBakingTemperature(multiple);
            oven.methods.stakeNbake(tokenAmountRaw, bakingTemp, isLP).send({from: account})
            .on('confirmation', () => {
                console.log('confirmed');
            })
            .on('transactionHash', (hash) => {
                setTxnHash(hash);
            })
            .on('receipt', () => {
                // setIsTransacting(false);
                setIsStakedImgModalOpen(true);
                reloadData(true);
            })
            .on('error', (error) => {
                console.error(error);
                setIsTransacting(false);
            });
        } catch (error) {
            console.error(error);
        }
    }
    
    //withdraw tokens from the contract
    const withdrawTokens = async (depositId, amount) => {
        try {
            setIsTransacting(true);
            const {web3, dough, oven} = getWeb3();
            console.log('Deposit ID', depositId);
            console.log('Is LP', isLP);
            oven.methods.removeWhenGoldenBrown(depositId, isLP).send({from: account})
            .on('confirmation', () => {
                console.log('confirmed');
            })
            .on('transactionHash', (hash) => {
                setTxnHash(hash);
            })
            .on('receipt', () => {
                // setAmountClaimed(pending);
                setActionModalAmount(amount);
                setActionModalMode('withdraw')
                setIsActionModalOpen(true);
                reloadData(true);
            })
            .on('error', (error) => {
                console.error(error);
                setIsTransacting(false);
            });
        } catch (error) {
            console.error(error);
        }
    }

    //claim rewards from the contract
    const claimRewards = async (depositId, amount) => {
        try {
            setIsTransacting(true);
            const {web3, dough, oven} = getWeb3();
            oven.methods.collectBread(depositId, isLP).send({from: account})
            .on('confirmation', () => {
                console.log('confirmed');
            })
            .on('transactionHash', (hash) => {
                setTxnHash(hash);
            })
            .on('receipt', () => {
                // setIsTransacting(false);
                // setIsStakedImgModalOpen(true);
                console.log('Amount Claimed', amount);
                setActionModalAmount(amount);
                setActionModalMode('claim');
                setIsActionModalOpen(true);
                reloadData(true);
            })
            .on('error', (error) => {
                console.error(error);
                setIsTransacting(false);
            });
        } catch (error) {
            console.error(error);
        }
    }

    //compound rewards in the contract
    const compoundRewards = async (depositId, newTargetMultiple, amount) => {
        try {
            setIsTransacting(true);
            const {web3, dough, oven} = getWeb3();
            const bakingTemp = computeBakingTemperature(newTargetMultiple);
            console.log(`Params: ${depositId}, ${bakingTemp}, ${isLP}`)
            oven.methods.stayBaked(depositId, bakingTemp, isLP).send({from: account})
            .on('confirmation', () => {
                console.log('confirmed');
            })
            .on('transactionHash', (hash) => {
                setTxnHash(hash);
            })
            .on('receipt', () => {
                // setIsTransacting(false);
                // setIsStakedImgModalOpen(true);
                setActionModalAmount(amount);
                setActionModalMode('compound');
                setIsActionModalOpen(true);
                reloadData(true);
            })
            .on('error', (error) => {
                console.error(error);
                setIsTransacting(false);
            });
        } catch (error) {
            console.error(error);
        }
    }

    //compound rewards in the contract
    const jeet = async (depositId, amount) => {
        try {
            setIsTransacting(true);
            const {web3, dough, oven} = getWeb3();
            oven.methods.jeet(depositId, isLP).send({from: account})
            .on('confirmation', () => {
                console.log('confirmed');
            })
            .on('transactionHash', (hash) => {
                setTxnHash(hash);
            })
            .on('receipt', () => {
                // setIsTransacting(false);
                // setIsStakedImgModalOpen(true);
                setActionModalAmount(amount);
                setActionModalMode('jeet');
                setIsActionModalOpen(true);
                reloadData(true);
            })
            .on('error', (error) => {
                console.error(error);
                setIsTransacting(false);
            });
        } catch (error) {
            console.error(error);
        }
    }

    //get all deposits for user
    const getDeposits = async () => {
        try {
            const activeDepositsFormatted = isLP ? props.activeDepositsFormattedLP : props.activeDepositsFormatted;
            const weightedAvgAPR = await getWeightedAvgAPR(activeDepositsFormatted);
            console.log('Weighted Avg APR', weightedAvgAPR);
            setCurrAPRForPool(weightedAvgAPR);

            const activeDepositsForUser = await getActiveDepositsByUser(activeDepositsFormatted, account);
            console.log('Active Deposits', activeDepositsForUser);
            // activeDepositsForUser[0].currentAPR = 2.2345; //TODO: remove this line
            setDeposits(activeDepositsForUser.reverse());
            getStakedDough(activeDepositsForUser);
            getTotalPendingRewards(activeDepositsForUser);
            return activeDepositsForUser;
        } catch (error) {
            console.error(error);
        }
    }

    //get expected apr
    const getExpectedAPR = async (amount, multiple) => {
        try {
            const activeDepositsFormatted = isLP ? props.activeDepositsFormattedLP : props.activeDepositsFormatted;
            const weightedAvgAPR = await getWeightedAvgAPR(activeDepositsFormatted);
            const {totalMultiple, totalTokens, averageMultiple} = await getWeightedAvgMultiple(activeDepositsFormatted, true);
            const expectedAPR = await computeExpectedAPR(totalTokens, totalMultiple, weightedAvgAPR, amount, multiple);
            console.log('Expected APR', expectedAPR);
            return expectedAPR;
        } catch (error) {
            console.error(error);
        }
    }

    //toggle liquidity providing
    const setLPMode = async (e, _isLP) => {
        // e.preventDefault();
        await setIsLP(_isLP);
    }

    //connect wallet to the dApp
    const connectWallet = () => {
        setIsWalletConnectModalOpen(true);
    }

    //close the wallet connect modal
    const onConnectWalletModalClose = () => {
        setIsWalletConnectModalOpen(false);
    }

    //close the wallet connect modal
    const onTransactingModalClose = () => {
        setIsTransacting(false);
        setTxnHash('');
    }
            
    // console.log("ISLP: ", isLP)
    return (
        <>
            <div className='dapp-container'>
                <Navbar page="dapp" account={account} connectWallet={connectWallet}/>
                <div className='dapp-sub-container'>
                    <div className='dapp-form-container'>
                        <div className='brioche-fontface dapp-title'>
                            BAKE YOUR DOUGH
                        </div>
                        <div className='dapp-toggle-container'>
                            {isLP ?
                                <>
                                <button className='brioche-fontface dapp-toggle-btn2' onClick={(e) => setLPMode(e, false)}>DOUGH</button>
                                <button className='brioche-fontface dapp-toggle-btn' disabled>DOUGH/WETH LP</button>
                                </>
                                :
                                <>
                                <button className='brioche-fontface dapp-toggle-btn' disabled>DOUGH</button>
                                <button className='brioche-fontface dapp-toggle-btn2' onClick={(e) => setLPMode(e, true)}>DOUGH/WETH LP</button>
                                </>
                            }
                        </div>
                        <StakeForm 
                            // depositTokens={depositTokens}
                            // approveTokens={approveTokens}
                            tokenBalance={tokenBalance}
                            tokenAllowance={tokenAllowance}
                            totalPendingRewards={totalPendingRewards}
                            stakedDough={stakedDough}
                            currPrice={currPrice}
                            totalShares={totalShares}
                            isLP={isLP}
                            approveTokens={approveTokens}
                            depositTokens={depositTokens}
                            setIsStakedImgModalOpen={setIsStakedImgModalOpen}
                            calculateExpectedAPR={getExpectedAPR}
                            computeBakingTemperature={computeBakingTemperature}
                            getExpectedAPR={getExpectedAPR}
                            setTargetMultiple={setTargetMultiple}
                            setIsRulesModalOpen={setIsRulesModalOpen}
                            navigate={navigate}
                        />
                    </div>
                    <div className="le-oven-container">
                        <div className="le-oven-gradient">
                            <div className='brioche-fontface oven-display'>
                                NOW BAKING
                            </div>
                            <div className='oven-deposit-container'>
                                {deposits.map((deposit, index) => {
                                    return (
                                        <DepositCard
                                            key={index} 
                                            deposit={deposit}
                                            claimRewards={claimRewards}
                                            withdrawTokens={withdrawTokens}
                                            compoundRewards={compoundRewards}
                                            jeet={jeet}
                                            currPrice={currPrice}
                                            isLP={isLP}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <WalletSelectModal isOpen={isWalletConnectModalOpen} closeModal={onConnectWalletModalClose} />
                <TransactingModal isOpen={isTransacting} closeModal={onTransactingModalClose} txnHash={txnHash} />
                <StakedImgModal isOpen={isStakedImgModalOpen} closeModal={() => setIsStakedImgModalOpen(false)} multiple={targetMultiple} />
                <ActionModal isOpen={isActionModalOpen} closeModal={() => setIsActionModalOpen(false)} amount={actionModalAmount} mode={actionModalMode} isLP={isLP} />
                <RulesModal isOpen={isRulesModalOpen} closeModal={() => setIsRulesModalOpen(false)} />
            </div>
        </>
    )
}