import {
    VStack,
    HStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    Text
  } from "@chakra-ui/react";
import { useEffect } from "react";
import { Image } from "@chakra-ui/react";
import { ChakraProvider } from '@chakra-ui/react';
import themeStaked from '../themes/themeStaked';
import {isBrowser, isMobile } from 'react-device-detect';
import doughboy from '../doughboy.png';
  

export default function RulesModal({ isOpen, closeModal }) {

    const onBtnClick = () => {
        closeModal();
    }
  
    return (
      <ChakraProvider theme={themeStaked}>
      <Modal isOpen={isOpen} onClose={closeModal} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent w={isMobile ? "90%" : "100%"} h='50%' overflowY='scroll'>
        <VStack>
          <ModalCloseButton
            _focus={{
              boxShadow: "none"
            }}
          />
          <ModalBody padding="5%">
            <VStack>
              <img src={doughboy} style={{marginBottom:'1%', marginTop:'2%', width:'50%'}} />
              <Text className="brioche-fontface" marginBottom='5%' marginTop='1%' color='#FEFF04' fontSize='2.3vh' w="80%" lineHeight='2.5rem'>
                Rules of Le Oven:
              </Text>
              <Text className="brioche-fontface" marginBottom='5%' color='#FEFF04' fontSize='2vh' w="80%" lineHeight='2.5rem'>
                1. <b>Deposit</b> either $DOUGH or DOUGH/WETH LP Tokens to earn $DOUGH rewards <br/><br/>
                2. Set a <b>target price multiple</b> - max 100x, min 2x, in increments of 0.25x <br/><br/>
                3. Before your target is reached you can: <br/>
                - <b>Claim</b> or <b>Compound</b> rewards up to principal staked amount <br/>
                - <b>Jeet</b> (withdraw principal with penalty, already claimed rewards get deducted as well) <br/><br/>
                4. Once target is reached you can: <br/>
                - <b>Withdraw</b> principal + any remaining rewards<br/><br/>
                Happy Baking 🍞
              </Text>
              <HStack w="100%" marginBottom='3%' justifyContent="center">
                <Button
                    variant="solid"
                    backgroundColor='#000000'
                    color='#FEFF04'
                    borderRadius='0px'
                    border='1px solid #FEFF04'
                    _hover={{
                    border:'2px',
                    borderColor:'#000000',
                    backgroundColor:'#FEFF04',
                    color:'#000000'
                    }}
                    onClick={() => {
                      onBtnClick('demo');
                    }}
                    w="40%"
                >
                    <Text className="brioche-fontface" fontSize='xs'>Close</Text>
                </Button>
              </HStack>
              
            </VStack>
          </ModalBody>
          </VStack>
        </ModalContent>
      </Modal>
      </ChakraProvider>
    );
  }
  